import React, { useState } from 'react'
import SemanticDatepicker from 'react-semantic-ui-datepickers';
import { Form, Icon, Input, Button } from 'semantic-ui-react'
import './mdl-report-units.css'

const MDL_REPORT_UNITS = ({ set_mdl_open }) => {

    const [currentDate, setNewDate] = useState(null);

    function closeModal() {
        set_mdl_open(false);
    }

    function onChangeDate(e, data) {
        setNewDate(data.value)
    }
    
    return (
        <>
            <Form>
                <div className="modal-header">
                    <div className="modal-header-title">Report Time Range</div>
                    <div className="modal-header-close" onClick={() => set_mdl_open(false)}>
                        <Icon name="times circle outline" />
                    </div>
                </div>
                <div className='modal-content'>
                    <Form.Group>
                        <SemanticDatepicker
                            id='start_date'
                            required={true}
                            name='start_date'
                            label='Start Date'
                            fluid
                            onChange={onChangeDate}
                        />
                    </Form.Group>
                    <Form.Group>
                        <SemanticDatepicker
                            id='end_date'
                            name='lend_date'
                            label='End Date'
                            fluid
                            required={true}
                            onChange={onChangeDate} 
                        />
                    </Form.Group>
                </div>
                <div className="modal-footer">
                    <Button color="red" onClick={() => closeModal()}>Cancel</Button>
                    <Button color="green">Submit</Button>
                </div>
            </Form>
        </>
    )
}

export default MDL_REPORT_UNITS