import React, { useContext, useEffect, useState } from "react";
import { Button, Form, Grid, Icon, Modal } from "semantic-ui-react";
import "./BuildingHeatmap.css";
import { AppContext } from "../../../Context/AppContext";
import MDL_UNIT_HISTORY from "./Components/mdl_unit_history/mdl_unit_history";
import MDL_PROGRESS from "../../../components/cmp-progress/cmp-progress";
import {
  downloadHeatmapAPI,
  getBuildingHeatmapAPI,
  getSingleBuilding,
} from "../../../constants";
import { useParams, useHistory } from "react-router-dom";
import moment from "moment";
import { isNumber } from "lodash";

export default function BuildingHeatmap() {
  const { building_id } = useParams();
  const history = useHistory();
  const [var_mdl_progress, set_mdl_progress] = useState(false);
  const { buildingUnits, selectedBuilding, bInfo } = useContext(AppContext);
  const [building_units, setBuildingUnits] = buildingUnits;
  const [, setBuildingInfo] = bInfo;
  const [selected_building, setSelectedBuilding] = selectedBuilding;
  const [building_heatmap, setBuildingHeatmap] = useState({});
  const [building_heatMap_data, set_building_heatMap_data] = useState([]);
  const [var_mdl_unit_history, set_mdl_unit_history] = useState(false);
  const [filter, setFilter] = useState([
    {
      value: 3,
      text: "3 Months",
    },
    {
      value: 6,
      text: "6 Months",
    },
    {
      value: 9,
      text: "9 Months",
    },
    {
      value: 12,
      text: "12 Months",
    },
  ]);
  const [selected_unit, set_selected_unit] = useState(null);
  const [selected_filter, setSelectedFilter] = useState(3);
  const [legends, setLegends] = useState({});

  useEffect(() => {
    if (selected_unit) {
      openModalUnitHistory();
    } else {
      closeModalUnitHistory();
    }
  }, [selected_unit]);

  useEffect(() => {
    if (building_heatmap.length) getLegendValues();
  }, [building_heatmap]);

  function openModalUnitHistory() {
    set_mdl_unit_history(true);
  }

  function closeModalUnitHistory() {
    set_mdl_unit_history(false);
  }

  useEffect(() => {
    if (selected_filter) getBuildingHeatmap();
  }, [selected_filter]);

  useEffect(() => {
    prepareBuildingInfo();
  }, []);

  async function getBuildingUnits() {
    await fetch(getSingleBuilding + building_id)
      .then((response) => response.json())
      .then((response) => {
        const final_data = response.data[0];
        setBuildingUnits(final_data.units ? final_data.units : []);
      });
  }

  async function prepareBuildingInfo() {
    if (!selected_building && localStorage.getItem("building")) {
      const buildings = await JSON.parse(localStorage.getItem("building"));
      setBuildingInfo(buildings);
      let temp_building = [];
      if (buildings && buildings.length > 0) {
        temp_building = buildings.filter((b) => b.value === building_id);
      }
      if (temp_building.length) {
        setSelectedBuilding(temp_building[0]);
        getBuildingHeatmap();
        getBuildingUnits();
      } else {
        history.back();
      }
    } else {
      getBuildingHeatmap();
    }
  }

  function getIntegerFromString(val) {
    // console.log("Data::::regex", val);
    var re = /^(-?\d*)[^0-9]*(\d*)\.([\s\S]*?)$/;
    return parseInt(val?.replace(re, "$1$2"), 10);
  }

  function isAlphanumericWithSpace(inputString) {
    // Use a regular expression to check if the string is alphanumeric with space
    return /^[0-9]+$/.test(inputString) && /^[a-zA-Z]+$/.test(inputString);
  }

  async function getBuildingHeatmap() {
    set_mdl_progress(true);
    let API_URL = getBuildingHeatmapAPI + building_id;
    // let API_URL = "https://homepro-test.ps19testserver.com/api/buildings/heat-map/08f5e439-32db-4abb-aa9e-2778042d7dcb";
    if (selected_filter) {
      API_URL += `?month=${selected_filter}`;
    }

    await fetch(API_URL)
      .then((response) => response.json())
      .then((response) => {
        if (response.data) {
          // chunkArray(response.data, 100);
          console.log("Data:::", response.data);
          setBuildingHeatmap(response.data);
        } else {
          setBuildingHeatmap({});
        }
        set_mdl_progress(false);
      });
  }

  function onChangeFilter(event, { value }) {
    if (value) setSelectedFilter(value);
  }

  function getActivityLevel(unit) {
    let className = "";
    if (unit) {
      if (unit.activity_level === "Low activity") {
        className = "light-activity";
      } else if (unit.activity_level === "Moderate activity") {
        className = "moderate-activity";
      } else if (unit.activity_level === "High activity") {
        className = "heavy-activity";
      } else if (unit.activity_level === "No activity") {
        className = "none-activity";
      } else if (
        !unit.activity_level &&
        !unit.prep_level &&
        !unit.sanitation_level &&
        !unit.last_invoice_id
      ) {
        className = "not-serviced";
      }
    } else {
      className = "";
    }
    return className;
  }

  function getLegendValues() {
    var no_activity = 0;
    var light_activity = 0;
    var medium_activity = 0;
    var high_activity = 0;
    var refused_entry = 0;
    var prepared = 0;
    var not_prepared = 0;
    var average_sanitation = 0;
    var low_sanitation = 0;
    var high_sanitation = 0;
    var structural_issues = 0;

    building_heatmap.forEach((data) => {
      no_activity += data.no_activity_count;
      light_activity += data.low_activity_count;
      medium_activity += data.medium_activity_count;
      high_activity += data.high_activity_count;
      refused_entry += data.refused_entry_count;
      prepared += data.prepared_count;
      not_prepared += data.not_prepared_count;
      average_sanitation += data.average_sanitation_count;
      low_sanitation += data.low_sanitation_count;
      high_sanitation += data.high_sanitation_count;
      structural_issues += data.high_sanitation_count;
    });

    setLegends({
      no_activity,
      light_activity,
      medium_activity,
      high_activity,
      refused_entry,
      average_sanitation,
      low_sanitation,
      high_sanitation,
      prepared,
      not_prepared,
    });
  }

  function getIconName(filtered_value) {
    let icon = "";
    if (filtered_value.refused_entry) {
      icon = <Icon color="red" name="minus circle"></Icon>;
    } else if (
      filtered_value.not_prepare ||
      filtered_value.prep_level === "Not Prepared"
    ) {
      // icon = <Icon name="plus circle"></Icon>;
      icon = <Icon color="orange" name="close"></Icon>;
    } else if (filtered_value.sanitation) {
      icon = <Icon name="paint brush"></Icon>;
    }
    // else if (filtered_value.structural_issues) {
    //   icon = "🛠️";
    // }
    else {
      icon = "";
    }

    return icon;
  }

  function mergeArrays(arr1, arr2) {
    return arr2.map((obj2) => {
      const matchingObj = arr1.find((obj1) => obj1.unit === obj2.unit);
      return matchingObj ? { ...obj2, ...matchingObj } : obj2;
    });
  }

  // function getInstance() {
  //   if (!building_heatmap || !building_heatmap.floors) {
  //     return <></>;
  //   }

  //   const max_children = building_heatmap?.maxChildren?.count || 0;
  //   const floors = Object.keys(building_heatmap.floors);
  //   return floors.map((floor, i) => {
  //     const floorsChildren = building_heatmap.floors[floor];
  //     if (floorsChildren.length > 0) {
  //       const firstValue = getIntegerFromString(floorsChildren[0].unit);
  //       return (
  //         <div
  //           className="flex row-wrapper"
  //           style={{ alignItems: "center" }}
  //           key={i}
  //         >
  //           <span className="text-center first-column-spacing text-center">
  //             {floor}
  //           </span>
  //           {(() => {
  //             const jsxArray = [];
  //             let index = 0;
  //             let child_index = 0;
  //             do {
  //               const child = floorsChildren[child_index];
  //               const numberValue = getIntegerFromString(child?.unit);
  //               if (isNaN(numberValue)) {
  //                 child_index++;
  //                 jsxArray.push(
  //                   <div
  //                     className={`column-border ${i === 0 ? "borderTop" : ""} ${
  //                       child && getActivityLevel(child)
  //                     }`}
  //                     onClick={() => {
  //                       set_selected_unit(child.unit);
  //                     }}
  //                     computer={1}
  //                     tablet={2}
  //                     mobile={4}
  //                     key={index}
  //                   >
  //                     <div className="flex flex-column flex-center icon-container-size">
  //                       <span className="text-center text-wrap">
  //                         {child && getIconName(child)}
  //                       </span>
  //                       <span className="text-center text-wrap">
  //                         {child?.unit}
  //                       </span>
  //                     </div>
  //                   </div>
  //                 );
  //               } else {
  //                 const isMatch = firstValue + index === numberValue;

  //                 if (isMatch) {
  //                   child_index++;
  //                   // JSX for matching condition
  //                   jsxArray.push(
  //                     <div
  //                       className={`column-border ${
  //                         i === 0 ? "borderTop" : ""
  //                       } ${isMatch ? getActivityLevel(child) : ""}`}
  //                       onClick={() => {
  //                         set_selected_unit(child.unit);
  //                       }}
  //                       computer={1}
  //                       tablet={2}
  //                       mobile={4}
  //                       key={index}
  //                     >
  //                       <div className="flex flex-column flex-center icon-container-size">
  //                         <span className="text-center text-wrap">
  //                           {isMatch && getIconName(child)}
  //                         </span>
  //                         <span className="text-center text-wrap">
  //                           {child.unit}
  //                         </span>
  //                       </div>
  //                     </div>
  //                   );
  //                 } else if (isAlphanumericWithSpace(child?.unit)) {
  //                   child_index++;
  //                   // JSX for matching condition
  //                   jsxArray.push(
  //                     <div
  //                       className={`column-border ${
  //                         i === 0 ? "borderTop" : ""
  //                       } ${getActivityLevel(child)}`}
  //                       onClick={() => {
  //                         set_selected_unit(child.unit);
  //                       }}
  //                       computer={1}
  //                       tablet={2}
  //                       mobile={4}
  //                       key={index}
  //                     >
  //                       <div className="flex flex-column flex-center icon-container-size">
  //                         <span className="text-center text-wrap">
  //                           {getIconName(child)}
  //                         </span>
  //                         <span className="text-center text-wrap">
  //                           {child.unit}
  //                         </span>
  //                       </div>
  //                     </div>
  //                   );
  //                 } else {
  //                   // JSX for non-matching condition
  //                   jsxArray.push(
  //                     <div
  //                       className={`empty-column-border`}
  //                       l
  //                       computer={1}
  //                       tablet={2}
  //                       mobile={4}
  //                       key={index}
  //                     >
  //                       <div className="flex flex-column flex-center icon-container-size">
  //                         <span className="text-center"></span>
  //                         <span className="text-center"></span>
  //                       </div>
  //                     </div>
  //                   );
  //                 }
  //               }

  //               index++;
  //             } while (index <= max_children);

  //             return jsxArray;
  //           })()}
  //         </div>
  //       );
  //     }

  //     return null;
  //   });
  // }
  function getInstance() {
    if (!building_heatmap || !building_heatmap.floors) {
      return <></>;
    }
    const floors = Object.keys(building_heatmap.floors);
    return floors.map((floor, i) => {
      var floorsChildren = building_heatmap.floors[floor];
      if (floorsChildren.length > 0) {
        return (
          <div
            className="flex row-wrapper"
            style={{ alignItems: "center" }}
            key={i}
          >
            <span className="text-center first-column-spacing text-center">
              {floor}
            </span>
            {(() => {
              const jsxArray = [];
              let index = 0;
              let child_index = 0;
              var previousNumber = null;
              var tempVals = [];
              for (var k = 0; k < floorsChildren.length; k++) {
                if (isAlphanumericWithSpace(floorsChildren[k]?.unit)) {
                  tempVals.push(floorsChildren[k]);
                } else {
                  var currentNumber = getIntegerFromString(
                    floorsChildren[k]?.unit
                  );
                  if (previousNumber !== null && currentNumber !== null) {
                    while (++previousNumber < currentNumber) {
                      tempVals.push(null);
                    }
                  }
                  tempVals.push(floorsChildren[k]);
                  previousNumber = currentNumber;
                }
              }
              floorsChildren = tempVals;
              do {
                const child = floorsChildren[index];

                jsxArray.push(
                  <div
                    className={`column-border ${
                      i === 0 ? "borderTop" : ""
                    } ${getActivityLevel(child)}`}
                    onClick={() => {
                      child && set_selected_unit(child.unit);
                    }}
                    computer={1}
                    tablet={2}
                    mobile={4}
                    key={index}
                  >
                    <div className="flex flex-column flex-center icon-container-size">
                      <span className="text-center text-wrap">
                        {child && getIconName(child)}
                        {child?.structural_issues && " 🛠️"}
                      </span>
                      <span className="text-center text-wrap">
                        {child?.unit}
                      </span>
                    </div>
                  </div>
                );
                index++;
              } while (index < floorsChildren.length);

              return jsxArray;
            })()}
          </div>
        );
      }

      return null;
    });
  }

  async function downloadReport() {
    const url = `${
      downloadHeatmapAPI + building_id
    }?month=${selected_filter}&download=true`;
    // console.log("Url", url);
    try {
      window.open(url, "_blank");
    } catch (error) {
      console.error("Error:", error);
    }
  }

  return (
    <div className="app-content">
      <div className="app-backto-prev-page">
        <div
          className="app-goback-button"
          onClick={() => {
            history.goBack();
          }}
        >
          <Icon name="arrow left" /> Go Back
        </div>
      </div>
      <div className="app-card">
        <div className="app-card-header">
          <div className="app-card-title">Building Heatmap</div>
          <div className="app-content-action-right">
            <Form.Select
              className="sortby_options"
              id="sortby_options"
              name="sortby_options"
              placeholder="Select Filter"
              label="Filter: "
              onChange={onChangeFilter}
              value={selected_filter}
              options={filter}
            />
            {/* Button to download heat */}
            <Button
              color="green"
              type="button"
              content="Download"
              onClick={() => {
                downloadReport();
              }}
              icon="download"
            ></Button>
          </div>
        </div>
        <Grid>
          <Grid.Column computer={4} tablet={16} className="flex flex-column">
            <span className="flex">
              <span className="building-name-title">Address:</span>
              <span>{selected_building?.text}</span>
            </span>

            <span className="flex">
              <span className="building-name-title">Date:</span>
              <span>{moment().format("LL")}</span>
            </span>
          </Grid.Column>
          <Grid.Column
            style={{ margin: "" }}
            computer={12}
            tablet={16}
            className=""
          >
            <table class="mobile-table-row">
              <thead>
                <tr class="table-border mobile-table">
                  {/* <td class="table-data">Legend</td> */}
                  <td class="table-data text-bold text-center">Not Serviced</td>
                  <td class="table-data text-bold text-center">
                    Light Activity
                  </td>
                  <td class="table-data text-bold text-center">
                    Moderate Activity
                  </td>
                  <td class="table-data text-bold text-center">
                    Heavy Activity
                  </td>
                  <td class="table-data text-bold text-center">
                    No Activity
                  </td>
                  <td class="table-data text-bold text-center">
                    Not Prepared<Icon color="red" name="close"></Icon>
                  </td>
                  <td class="table-data text-bold text-center">
                    Refused Entry <Icon color="red" name="minus circle"></Icon>
                  </td>
                  <td class="table-data text-bold text-center">
                    Sanitation <Icon name="paint brush"></Icon>
                  </td>
                  <td class="table-data text-bold text-center">
                    Structural 🛠️
                  </td>
                </tr>
              </thead>
              <tbody>
                {building_heatmap?.legend && (
                  <tr class="table-border mobile-table">
                    {/* <td class="table-data">%</td> */}
                    <td class="not-serviced table-data text-center">
                      {building_heatmap.legend["Not_Serviced"].count}
                    </td>
                    <td class="light-activity table-data text-center">
                      {building_heatmap.legend["Light_Activity"].count}
                    </td>
                    <td class="moderate-activity table-data text-center">
                      {building_heatmap.legend["Moderate_Activity"].count}
                    </td>
                    <td class="heavy-activity table-data text-center">
                      {building_heatmap.legend["Heavy_Activity"].count}
                    </td>
                    <td class="table-data text-center">
                      {building_heatmap.legend["None_Activity"].count}
                    </td>
                    <td class="table-data text-center">
                      {building_heatmap.legend["Not_Prepared"].count}
                    </td>

                    <td class="table-data text-center">
                      {building_heatmap.legend["Refused_Entry"].count}
                    </td>
                    <td class="table-data text-center">
                      {building_heatmap.legend["Sanitation"].count}
                    </td>
                    <td class="table-data text-center">
                      {building_heatmap.legend["Structural_Issues"].count}
                    </td>
                  </tr>
                )}
                {building_heatmap?.legend && (
                  <tr class="table-border mobile-table">
                    {/* <td class="table-data">%</td> */}
                    <td class="not-serviced table-data text-center">
                      {building_heatmap.legend["Not_Serviced"].percentage}%
                    </td>
                    <td class="light-activity table-data text-center">
                      {building_heatmap.legend["Light_Activity"].percentage}%
                    </td>
                    <td class="moderate-activity table-data text-center">
                      {building_heatmap.legend["Moderate_Activity"].percentage}%
                    </td>
                    <td class="heavy-activity table-data text-center">
                      {building_heatmap.legend["Heavy_Activity"].percentage}%
                    </td>
                    <td class="table-data text-center">
                      {building_heatmap.legend["None_Activity"].percentage}%
                    </td>
                    <td class="table-data text-center">
                      {building_heatmap.legend["Not_Prepared"].percentage}%
                    </td>

                    <td class="table-data text-center">
                      {building_heatmap.legend["Refused_Entry"].percentage}%
                    </td>
                    <td class="table-data text-center">
                      {building_heatmap.legend["Sanitation"].percentage}%
                    </td>
                    <td class="table-data text-center">
                      {building_heatmap.legend["Structural_Issues"].percentage}%
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </Grid.Column>
        </Grid>
        <div className="heatmap-container">
          <div className="" style={{ margin: "0" }}>
            {getInstance()}
          </div>
        </div>
      </div>

      {/***** MODAL: REPORT_UNITS **************************************************************************/}
      <Modal
        id="mdl-unit-history"
        dimmer={"inverted"}
        size="large"
        open={var_mdl_unit_history}
        onClose={() => set_selected_unit(null)}
      >
        <MDL_UNIT_HISTORY
          unit_number={selected_unit}
          set_mdl_open={set_mdl_unit_history}
          set_selected_unit={set_selected_unit}
        />
      </Modal>

      {/***** MODAL: PROGRESS **************************************************************************/}

      {/***** MODAL: PROGRESS **************************************************************************/}

      <Modal
        id="mdl-progress"
        dimmer={"inverted"}
        open={var_mdl_progress}
        closeOnDimmerClick={false}
        closeOnEscape={false}
        onClose={() => {}}
      >
        <MDL_PROGRESS
          set_mdl_open={set_mdl_progress}
          var_modaltitle="Please wait"
          var_message={"Please wait..."}
        ></MDL_PROGRESS>
      </Modal>

      {/***** END MODAL: PROGRESS **************************************************************************/}
    </div>
  );
}
