import React, { useContext, useEffect, useState } from "react";
import { Button, Form, Grid, Icon, Modal } from "semantic-ui-react";
import { AppContext } from "../../../Context/AppContext";
import { downloadSummaryAPI, getBuildingSummaryAPI } from "../../../constants";
import "./BuildingHistory.css";
import moment from "moment";
import MDL_PROGRESS from "../../../components/cmp-progress/cmp-progress";
import { useParams, useHistory } from "react-router-dom";

const BuildingHistory = () => {
  const { building_id } = useParams();
  const history = useHistory();
  const [var_mdl_progress, set_mdl_progress] = useState(false);
  const { selectedBuilding, bInfo } = useContext(AppContext);
  const [selected_building, setSelectedBuilding] = selectedBuilding;
  const [building_summary, setBuildingSummary] = useState([]);
  const [, setBuildingInfo] = bInfo;
  const [filter, setFilter] = useState([
    {
      value: 3,
      text: "3 Months",
    },
    {
      value: 6,
      text: "6 Months",
    },
    {
      value: 9,
      text: "9 Months",
    },
    {
      value: 12,
      text: "12 Months",
    },
  ]);
  const [selected_filter, setSelectedFilter] = useState(3);

  useEffect(() => {
    if (selected_filter) getBuildingSummary();
  }, [selected_filter]);

  useEffect(() => {
    prepareBuildingInfo();
  }, []);

  async function prepareBuildingInfo() {
    if (!selected_building && localStorage.getItem("building")) {
      const buildings = await JSON.parse(localStorage.getItem("building"));
      setBuildingInfo(buildings);
      let temp_building = [];
      if (buildings && buildings.length > 0) {
        temp_building = buildings.filter((b) => b.value === building_id);
      }
      if (temp_building.length) {
        setSelectedBuilding(temp_building[0]);
        getBuildingSummary();
      } else {
        history.back();
      }
    } else {
      getBuildingSummary();
    }
  }

  async function downloadReport() {
    const url = `${
      downloadSummaryAPI + building_id
    }?month=${selected_filter}&download=true`;
    // console.log("Url", url);
    try {
      //.Use This to View the Report
      // const res = await fetch(url);
      // const responseType = res.headers.get("Content-Type") || res.headers.get("content-type");
      //   if (responseType.indexOf('application/json') === -1) {
      //       return res.blob();
      //   }
      window.open(url, "_blank");
    } catch (error) {
      console.error("Error:", error);
    }
  }

  async function getBuildingSummary() {
    set_mdl_progress(true);
    let API_URL = getBuildingSummaryAPI + building_id;
    if (selected_filter) {
      API_URL += `?month=${selected_filter}`;
    }

    await fetch(API_URL)
      .then((response) => response.json())
      .then((response) => {
        if (response.data) setBuildingSummary(response.data);
        else {
          setBuildingSummary([]);
        }
        set_mdl_progress(false);
      });
  }

  function onChangeFilter(event, { value }) {
    if (value) setSelectedFilter(value);
  }

  return (
    <div className="app-content">
      <div className="app-backto-prev-page">
        <div
          className="app-goback-button"
          onClick={() => {
            history.goBack();
          }}
        >
          <Icon name="arrow left" /> Go Back
        </div>
      </div>
      <div className="app-card">
        <div className="app-card-header">
          <div className="app-card-title building-name building-history">
            <span className="building-name-spacing">
              Building: {selected_building?.text}
            </span>

            <span className="building-date-spacing">
              Requested Date: {moment().format("LL")}
            </span>
          </div>
          <div className="app-content-action-right">
            <Form.Select
              className="sortby_options"
              id="sortby_options"
              name="sortby_options"
              placeholder="Select Filter"
              label="Time Range: "
              onChange={onChangeFilter}
              value={selected_filter}
              options={filter}
            />
            {/* Button to download the history */}
            <Button
              color="green"
              type="button"
              content="Download"
              onClick={() => {
                downloadReport();
              }}
              icon="download"
            ></Button>
          </div>
        </div>

        <Grid style={{ margin: "0" }}>
          {building_summary?.map((summary, index) => (
            <>
              <Grid.Row className="row-p0 border-top">
                <Grid.Column style={{ paddingTop: "4px" }}>
                  <span className="unit-number-spacing">
                    {summary.unit_number}
                  </span>
                </Grid.Column>
              </Grid.Row>
              <Grid.Column computer={2} tablet={3.5} mobile={7}>
                <div className="order-history-item">
                  <span>Order#</span>
                  <span>#{summary.order_number}</span>
                </div>
              </Grid.Column>
              <Grid.Column computer={2} tablet={3.5} mobile={7}>
                <div className="order-history-item">
                  <span>Treatment</span>
                  <span>
                    {summary.pest_name} {summary.treatment_name}
                  </span>
                </div>
              </Grid.Column>
              <Grid.Column computer={2} tablet={3.5} mobile={7}>
                <div className="order-history-item">
                  <span>Work Date</span>
                  <span>{moment(summary.work_date).format("MM-DD-YYYY")}</span>
                </div>
              </Grid.Column>
              <Grid.Column computer={2} tablet={3.5} mobile={7}>
                <div className="order-history-item">
                  <span>Activity Level</span>
                  <span>{summary.activity_level || "N/A"}</span>
                </div>
              </Grid.Column>
              <Grid.Column computer={3} tablet={3.5} mobile={7}>
                <div className="order-history-item">
                  <span>Sanitation</span>
                  <span>{summary.sanitation_level || "N/A"}</span>
                </div>
              </Grid.Column>
              <Grid.Column computer={3} tablet={3.5} mobile={7}>
                <div className="order-history-item">
                  <span>Structural</span>
                  <span>
                    {(summary.structural_issues && "Structural Issues") ||
                      "N/A"}
                  </span>
                </div>
              </Grid.Column>
              <Grid.Column computer={2} tablet={3.5} mobile={7}>
                <div className="order-history-item">
                  <span>Prep Level</span>
                  <span>{summary.prep_level || "N/A"}</span>
                </div>
              </Grid.Column>
              <Grid.Column
                computer={16}
                tablet={16}
                mobile={16}
                className="comment-spacing comment-text"
              >
                <span>{summary.comment || "No Comment"}</span>
              </Grid.Column>
              {/* </Grid.Row> */}
            </>
          ))}
        </Grid>
        {building_summary.length === 0 && !var_mdl_progress && (
          <Grid textAlign="center">
            <span className="no-data">
              No building history. Please change time range.
            </span>
          </Grid>
        )}
      </div>

      {/***** MODAL: PROGRESS **************************************************************************/}

      <Modal
        id="mdl-progress"
        dimmer={"inverted"}
        open={var_mdl_progress}
        closeOnDimmerClick={false}
        closeOnEscape={false}
        onClose={() => {}}
      >
        <MDL_PROGRESS
          set_mdl_open={set_mdl_progress}
          var_modaltitle="Please wait"
          var_message={"Please wait..."}
        ></MDL_PROGRESS>
      </Modal>

      {/***** END MODAL: PROGRESS **************************************************************************/}
    </div>
  );
};

export default BuildingHistory;
