import React, { useContext, useState, useEffect } from "react";
import { Table, Label, Modal, Search } from "semantic-ui-react";
import { AppContext } from "../../../Context/AppContext";
import { useHistory } from "react-router-dom";
import MDL_PROGRESS from "../../../components/cmp-progress/cmp-progress";

import {
  getAllBuildings,
  getTechOrders,
  resendOrderTechnician,
} from "../../../constants";
import AppPagination from "../../../components/cmp-pagination/Pagination";
import { useToasts } from "react-toast-notifications";

const TechHome = () => {
  /* VARIABLE DECLARATIONS
	---------------------------------------------------------------------------------*/
  const { userInfo, bInfo, selectedBuilding } = useContext(AppContext);
  const [, setBuildingInfo] = bInfo;
  const [, setSelectedBuilding] = selectedBuilding;
  const [user, setUser] = userInfo;
  const [searchResult, setSearchResult] = useState([]);
  const [buildings, setBuildings] = useState([]);
  const [requests, setRequests] = useState([]);
  const history = useHistory();
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(50);
  const [request_page, requestSetPage] = useState(1);
  const [request_limit, setRequestLimit] = useState(10);
  const [, setTotalRequests] = useState(0);
  const [var_mdl_progress, set_mdl_progress] = useState(false);
  const [, setTotalBuildings] = useState(0);
  const { addToast } = useToasts();

  /* ASYNC FUNCTIONS
	---------------------------------------------------------------------------------*/

  /* EVENT LISTENERS
	---------------------------------------------------------------------------------*/
  useEffect(() => {
    getBuildings();
  }, [page, limit]);

  useEffect(() => {
    getRequests();
  }, [request_page, request_limit]);

  async function getRequests(page_no, limit_range) {
    await fetch(
      getTechOrders +
        user.uuid +
        `&page=${page_no ? page_no : request_page}&limit=${
          limit_range ? limit_range : request_limit
        }`
    )
      .then((response) => response.json())
      .then((res) => {
        var requestTemp = [];
        if (res.statusCode === 200) {
          setTotalRequests(res.body.total_order);
          requestTemp = res.body.data;
          // if(res.body && res.body.data && res.body.data.length === 0){
          //     setPage(page-1)
          // }
        } else {
          requestTemp = [];
        }
        if ((request_page && request_page > 1) || (page_no && page_no > 1)) {
          requestTemp = [...requests, ...requestTemp];
        }
        setRequests(requestTemp);
      })
      .catch((error) => {
        console.log("Error:", error);
      });
  }

  function viewOrderdetails(id) {
    history.push({ pathname: "/technician-home/order-detail", state: { id } });
  }

  async function getBuildings(page_no, limit_range) {
    await fetch(
      getAllBuildings +
        `?page=${page_no ? page_no : page}&limit=${
          limit_range ? limit_range : limit
        }`
    )
      .then((response) => response.json())
      .then((res) => {
        var buildingFetch = [];
        const { data } = res;
        setTotalBuildings(res.total_building);
        if (res.code === 200) {
          buildingFetch = data;
          if (data.length === 0) {
            setPage(page - 1);
          }
        } else {
          buildingFetch = [];
        }
        if ((page && page > 1) || (page_no && page_no > 1))
          buildingFetch = [...buildings, ...buildingFetch];
        setBuildings(buildingFetch);
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  }

  async function resendInvite(request) {
    set_mdl_progress(true);
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Token " + user.token,
      },
      body: JSON.stringify({
        id: request.id,
        requested_count: request.request_count,
        user_id: user.user_id,
      }),
    };
    await fetch(resendOrderTechnician, requestOptions)
      .then((response) => response.json())
      .then(async (res) => {
        if (res.code && res.code === 401) {
          addToast("Session expired. Please Login again.", {
            appearance: "error",
            autoDismiss: true,
          });
          //SessionExpire();
          localStorage.removeItem("user");
          setUser(null);
        } else {
          if (res.statusCode === 200) {
            await getRequests();
            set_mdl_progress(false);
          }
        }
      })
      .catch((error) => {
        console.log("Error:", error);
      });
  }

  /* EVENT FUNCTIONS
	---------------------------------------------------------------------------------*/

  function goViewDetail(uuid) {
    history.push({
      pathname: "/technician-home/view-building",
      state: { data: uuid },
    });
  }

  async function goSendOrder(building) {
    await setBuildingInfo(building);
    await setSelectedBuilding({
      value: building.uuid,
      text: building.building_name,
    });
    history.push({
      pathname: `/technician-home/order-request/${building?.location_id}`,
    });
  }
  function doSearch(event) {
    const value = event.target.value.toString().toLowerCase();
    if (value === "") {
      setSearchResult([]);
    } else {
      var dummySearchData = [];
      buildings.map((val) => {
        if (
          value.includes(val.building_name.toString().toLowerCase()) ||
          val.building_name.toString().toLowerCase().includes(value)
        ) {
          dummySearchData = [...dummySearchData, val];
        }
      });
      setSearchResult(dummySearchData);
    }
  }

  /* RENDER APP
	---------------------------------------------------------------------------------*/

  return (
    <>
      <div className="app-content">
        <div className="app-card">
          <div className="app-card-header">
            <div className="app-card-title">
              Follow Up Requests ({requests.length})
            </div>
          </div>

          <div className="app-table-wrapper">
            <Table unstackable>
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell title="Building name">
                    Building
                  </Table.HeaderCell>
                  <Table.HeaderCell title="Customer email">
                    Email
                  </Table.HeaderCell>
                  <Table.HeaderCell title="No. of units">
                    Units
                  </Table.HeaderCell>
                  <Table.HeaderCell title="Current status of request">
                    Status
                  </Table.HeaderCell>
                  <Table.HeaderCell title="No. of requests">
                    Requests
                  </Table.HeaderCell>
                  <Table.HeaderCell textAlign="center">
                    Actions
                  </Table.HeaderCell>
                </Table.Row>
              </Table.Header>
              <Table.Body>
                {requests.length > 0 &&
                  requests.map((request, i) => (
                    <Table.Row key={i}>
                      <Table.Cell>{request.building_name}</Table.Cell>
                      <Table.Cell>{request.email}</Table.Cell>
                      <Table.Cell>
                        {request.units ? request.units.length : 0}
                      </Table.Cell>
                      <Table.Cell>
                        {request.status === "pending" && (
                          <div style={{ color: "#FA8C16" }}>Pending</div>
                        )}
                        {request.status === "accepted" && (
                          <div style={{ color: "#05881F" }}>Accepted</div>
                        )}
                        {request.status === "rejected" && (
                          <div style={{ color: "#f44336" }}>Rejected</div>
                        )}
                      </Table.Cell>
                      <Table.Cell>{request.request_count}</Table.Cell>
                      <Table.Cell
                        className="td-action"
                        collapsing
                        textAlign="right"
                      >
                        <Label
                          color="blue"
                          onClick={() => viewOrderdetails(request.id)}
                        >
                          View
                        </Label>
                        <Label
                          color="green"
                          onClick={() => resendInvite(request)}
                        >
                          Resend
                        </Label>
                      </Table.Cell>
                    </Table.Row>
                  ))}
              </Table.Body>
            </Table>
          </div>
          {requests.length === 0 && (
            <div className="nodata-placeholder">
              There is no request to display.
            </div>
          )}
        </div>
        <AppPagination
          limit={request_limit}
          setLimit={setRequestLimit}
          page={request_page}
          setPage={requestSetPage}
          rowsTotal={requests.length}
        />
        <div className="app-card">
          <div className="app-content-action app-content-action-search">
            <div className="app-card-header">
              <div className="app-card-title">
                {searchResult && searchResult.length > 0
                  ? "Buildings Found (" + searchResult.length + ")"
                  : "Buildings List (" + buildings.length + ")"}
              </div>
            </div>
            <div className="app-content-search">
              <Search
                placeholder="Search Building"
                onSearchChange={(value) => doSearch(value)}
                minCharacters={20000}
              />
            </div>
          </div>
          <div className="app-table-wrapper">
            <Table unstackable>
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell title="Building name">
                    Building
                  </Table.HeaderCell>
                  <Table.HeaderCell title="Building's customer">
                    Customer
                  </Table.HeaderCell>
                  <Table.HeaderCell title="Building's scheduler">
                    Scheduler
                  </Table.HeaderCell>
                  <Table.HeaderCell title="No. of units in building">
                    Units
                  </Table.HeaderCell>
                  <Table.HeaderCell textAlign="center">
                    Actions
                  </Table.HeaderCell>
                </Table.Row>
              </Table.Header>
              <Table.Body>
                {searchResult.length > 0 &&
                  searchResult.map((building, i) => (
                    <Table.Row key={i}>
                      <Table.Cell>{building.building_name}</Table.Cell>
                      <Table.Cell>{building.customer_name}</Table.Cell>
                      <Table.Cell>{building.scheduler_name}</Table.Cell>
                      <Table.Cell>
                        {building.units ? building.units.length : 0}
                      </Table.Cell>
                      <Table.Cell
                        className="td-action"
                        collapsing
                        textAlign="right"
                      >
                        <Label
                          color="blue"
                          onClick={() => goViewDetail(building.uuid)}
                        >
                          View
                        </Label>
                        <Label
                          color="green"
                          onClick={() => goSendOrder(building)}
                        >
                          Request Order
                        </Label>
                      </Table.Cell>
                    </Table.Row>
                  ))}
                {searchResult.length === 0 &&
                  buildings.map((building, i) => (
                    <Table.Row key={i}>
                      <Table.Cell>{building.building_name}</Table.Cell>
                      <Table.Cell>{building.customer_name}</Table.Cell>
                      <Table.Cell>{building.scheduler_name}</Table.Cell>
                      <Table.Cell>
                        {building.units ? building.units.length : 0}
                      </Table.Cell>
                      <Table.Cell
                        className="td-action"
                        collapsing
                        textAlign="right"
                      >
                        <Label
                          color="blue"
                          onClick={() => goViewDetail(building.uuid)}
                        >
                          View
                        </Label>
                        <Label
                          color="green"
                          onClick={() => goSendOrder(building)}
                        >
                          Request Order
                        </Label>
                      </Table.Cell>
                    </Table.Row>
                  ))}
              </Table.Body>
            </Table>
          </div>
          {buildings.length === 0 && (
            <div className="nodata-placeholder">
              There is no building to display.
            </div>
          )}
        </div>
        <AppPagination
          limit={limit}
          setLimit={setLimit}
          page={page}
          setPage={setPage}
          rowsTotal={buildings.length}
        />
      </div>

      {/***** MODAL: PROGRESS **************************************************************************/}
      <Modal
        id="mdl-progress"
        dimmer={"inverted"}
        open={var_mdl_progress}
        closeOnDimmerClick={false}
        closeOnEscape={false}
        onClose={() => {}}
      >
        <MDL_PROGRESS
          set_mdl_open={set_mdl_progress}
          var_modaltitle="Please wait"
          var_message={"Please wait..."}
        ></MDL_PROGRESS>
      </Modal>

      {/***** END MODAL: PROGRESS **************************************************************************/}
    </>
  );
};

export default TechHome;
