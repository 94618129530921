import React, { useContext, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { Button, Checkbox, Icon, Loader } from "semantic-ui-react";
import "./update-settings.css";
import { getSettingsCustomersList } from "../../../../constants";
import { AppContext } from "../../../../Context/AppContext";
import { useToasts } from "react-toast-notifications";

const UpdateSettings = ({ setUpdateAbleData, updateAbleData, refreshList }) => {
  /* VARIABLE DECLARATIONS
	---------------------------------------------------------------------------------*/
  //   const history = useHistory();
  const { userInfo } = useContext(AppContext);
  const [user] = userInfo;
  const [data, setData] = useState(updateAbleData?.data);
  const { addToast } = useToasts();
  const [isLoading, setIsLoading] = useState(false);

  /* EVENT LISTENERS
	---------------------------------------------------------------------------------*/
  //   useEffect(() => {
  //     if (history.location.state) {
  //       setData(history.location.state.data);
  //     } else {
  //       history.goBack();
  //     }
  //   }, []);

  /* ASYNC FUNCTIONS
	---------------------------------------------------------------------------------*/

  function submitChanges() {
    setIsLoading(true);
    let requestBody = {...data};
    delete  requestBody.name;
    delete requestBody.uuid

    const requestOptions = {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Token " + user.token,
      },
      body: JSON.stringify({
        ...requestBody,
        // user_id: user.user_id,
      }),
    };
    fetch(getSettingsCustomersList + `/${data?.uuid}`, requestOptions)
      .then((response) => response.json())
      .then((res) => {
        setIsLoading(false);
        if (res.code == 200) {
          setUpdateAbleData({ isShown: false, data: {} });
          refreshList();
        }

        addToast(res.message, {
          appearance: res.code != 200 ? "error" : "success",
          autoDismiss: true,
        });
      });
  }

  /* EVENT FUNCTIONS
	---------------------------------------------------------------------------------*/

  /* RENDER APP
	---------------------------------------------------------------------------------*/
  return (
    <>
      <div className="app-card-settings-update">
        <div className="app-card-header">
          <div className="app-card-title">Update Settings</div>
        </div>
        <div className="view-customer-section">
          <div className="view-customer-row">
            <div className="view-customer-label">Customer Name:</div>
            <div className="view-customer-field settings-bold">
              {data && data.name}
            </div>
          </div>
          <div className="view-customer-row">
            <div className="view-customer-label">Confirmation Email:</div>
            <div className="view-customer-field">
              <Checkbox
                checked={data?.confirmation_email}
                onChange={(e, { checked }) => {
                  let tempData = { ...data };
                  tempData.confirmation_email = checked ? 1 : 0;
                  setData(tempData);
                }}
                toggle
              />
            </div>
          </div>
          <div className="view-customer-row">
            <div className="view-customer-label">Minimum Service Charges:</div>
            <div className="view-customer-field">
              <Checkbox
                checked={data?.service_charge}
                onChange={(e, { checked }) => {
                  let tempData = { ...data };
                  tempData.service_charge = checked ? 1 : 0;
                  setData(tempData);
                }}
                toggle
              />
            </div>
          </div>
          <div className="view-customer-row">
            <div className="view-customer-label">Report Reading:</div>
            <div className="view-customer-field">
              <Checkbox
                checked={data?.report_reading}
                onChange={(e, { checked }) => {
                  let tempData = { ...data };
                  tempData.report_reading = checked ? 1 : 0;
                  setData(tempData);
                }}
                toggle
              />
            </div>
          </div>
          <div className="view-customer-row">
            <div className="view-customer-label">NOE(Notice Of Entry):</div>
            <div className="view-customer-field">
              <Checkbox
                checked={data?.notice_of_entry}
                onChange={(e, { checked }) => {
                  let tempData = { ...data };
                  tempData.notice_of_entry = checked ? 1 : 0;
                  setData(tempData);
                }}
                toggle
              />
            </div>
          </div>
          <div className="view-customer-row">
            <div className="view-customer-label">2nd Bed Bug:</div>
            <div className="view-customer-field">
              <Checkbox
                checked={data?.second_bedbug}
                onChange={(e, { checked }) => {
                  let tempData = { ...data };
                  tempData.second_bedbug = checked ? 1 : 0;
                  setData(tempData);
                }}
                toggle
              />
            </div>
          </div>
        </div>
        <div className="modal-footer">
          {!isLoading ? (
            <>
              <Button
                type="button"
                color="grey"
                onClick={() => {
                  setUpdateAbleData({ isShown: false, data: {} });
                }}
              >
                Close
              </Button>
              <Button
                disabled={updateAbleData?.data === data}
                type="submit"
                color="red"
                onClick={submitChanges}
              >
                Submit
              </Button>
            </>
          ) : (
            <>
              <Button type="reset">Please wait...</Button>
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default UpdateSettings;
