import React, {useState, useContext} from 'react'
import { Icon, Button, Form, Input } from 'semantic-ui-react';
import {  updateUser } from '../../../../constants';
import {useToasts } from 'react-toast-notifications';
import './mdl-update-customer.css'
import { AppContext } from '../../../../Context/AppContext';

export default function MDL_UPDATE_CUSTOMER({set_mdl_open, customer}) {

    /* VARIABLE DECLARATIONS
	---------------------------------------------------------------------------------*/
    const { userInfo,validateTextField } = useContext(AppContext);
    const [ user, setUser] = userInfo;
    const [ var_update_customer, set_update_customer ] = useState(customer);
    const { addToast } = useToasts();
    const [ errors, ] = useState([])

    /* EVENT LISTENERS
	---------------------------------------------------------------------------------*/
    
    /* EVENT FUNCTIONS
	---------------------------------------------------------------------------------*/
    const onChangeInput = (event) => {
        let name = event.target.name;
        let value = event.target.value;
        if(name==="contact_number"){
            var pattern = new RegExp(/^[0-9\b]+$/);
            if (!pattern.test(value)) {
            errors["contact_number"] = "Please enter only number.";
            }else if(value.length < 11){
            errors["contact_number"] = "Please enter valid phone number.";
            }
            else{
                errors["contact_number"]=null
            }
        }
        else if(name==="first_name"){
            if(validateTextField(value)===false){
                errors["first_name"]="Please enter valid first name."
            }else{
                errors["first_name"]=null
            }
        }
        else if(name==="last_name"){
            if(validateTextField(value)===false){
                errors["last_name"]="Please enter valid last name."
            }else{
                errors["last_name"]=null
            }
        }
        set_update_customer({...var_update_customer, [name]: value});
    }

    const onSubmit_updated_data = async (e) => {
        e.preventDefault();
        if(errors["first_name"]){
            errors["first_name"]="Please add valid first name."
        }
        else if(errors["last_name"]){
            errors["last_name"]="Please add valid last name."
        }
        else if(errors["contact_number"]){
            errors["contact_number"]="Please add valid number."
        }
        else{
        const { first_name,last_name,address,contact_number,company } = var_update_customer;
        const requestOptions = {
            method: 'PUT',
            headers: { 'Content-Type': 'application/json', 'Authorization': "Token "+user.token },
            body: JSON.stringify({ first_name,last_name,address,contact_number,company,user_id:user.user_id})};
        var apiUrl=updateUser+`${var_update_customer.uid}`;
        await fetch(apiUrl, requestOptions)
        .then(response => response.json())
        .then(res=>{
            if(res.code && res.code === 401){
                addToast("Session expired. Please Login again.", {
                    appearance: 'error',
                    autoDismiss: true,
                });
                //SessionExpire();
                localStorage.removeItem('user')
                setUser(null)
            }
            else{
                addToast(res.body.success, {
                    appearance: 'success',
                    autoDismiss: true,
                })
            }
            set_mdl_open(false);
        }).catch(err=>{
            console.error("Error:",err);
        });
        }
    }   
    
    

    /* RENDER APP
	---------------------------------------------------------------------------------*/
    return (
        <>
        <Form method="POST" onSubmit={onSubmit_updated_data}>
            <div className="modal-header">
                <div className="modal-header-title">Update Customer</div>
                <div className="modal-header-close" onClick={() => set_mdl_open(false)}>
                    <Icon name="times circle outline" />
                </div>
            </div>
            <div className="modal-content">
                <Form.Group>
                    <Form.Field
                        id='first_name'
                        name='first_name'
                        label='First Name'
                        control={Input}
                        required
                        onChange={onChangeInput}
                        value={var_update_customer.first_name}
                        error={errors["first_name"]}
                    />
                </Form.Group>
                <Form.Group>
                    <Form.Field
                        id='last_name'
                        name='last_name'
                        label='Last Name'
                        control={Input}
                        required
                        onChange={onChangeInput}
                        value={var_update_customer.last_name}
                        error={errors["last_name"]}
                    />
                </Form.Group>
                <Form.Group>
                    <Form.Field
                        id='email'
                        name='email'
                        label='Email'
                        disabled={true}
                        control={Input}
                        onChange={onChangeInput}
                        value={var_update_customer.email}
                    />
                </Form.Group>
                <Form.Group>
                    <Form.Field
                        id='company'
                        name='company'
                        label='Company'
                        required
                        control={Input}
                        onChange={onChangeInput}
                        value={var_update_customer.company}
                    />
                </Form.Group>
                <Form.Group>
                    <Form.Field
                        id='contact_number'
                        name='contact_number'
                        label='Contact Number'
                        control={Input}
                        required
                        error={errors["contact_number"]}
                        onChange={onChangeInput}
                        value={var_update_customer.contact_number}
                    />
                </Form.Group>
                <Form.Group>
                    <Form.Field
                        id='address'
                        name='address'
                        label='Address'
                        required
                        control={Input}
                        onChange={onChangeInput}
                        value={var_update_customer.address}
                    />
                </Form.Group>
            </div>

            <div className="modal-footer">
                <Button type="submit" color="red">
                    Update Customer
                </Button>
            </div>
        </Form>
        </>
    )
}
