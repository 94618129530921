import React, { useState, useEffect } from "react";
import dateFormat from "dateformat";
import _ from "lodash";
import { useHistory, useParams } from "react-router-dom";
import {
  Form,
  Button,
  Input,
  Icon,
  Message,
  Search,
  Modal,
  Grid,
} from "semantic-ui-react";
import { useContext } from "react";
import { AppContext } from "../../../Context/AppContext";
import MDL_SUCCESS from "../../../components/cmp-success/cmp-success";
import MDL_PROGRESS from "../../../components/cmp-progress/cmp-progress";
import MDL_CONFIRMATION from "../../../components/cmp-confirmation/cmp-confirmation";
import ADD_UNIT from "./cmp-add-unit/cmp-add-unit";

import "./OrderAutoFollowUp.css";
import {
  deleteDraftOrderUnits,
  deleteOrder,
  orderDetailsGet,
  postOrder,
} from "../../../constants";
import { useToasts } from "react-toast-notifications";

function OrderAutoFollowUp() {
  /* VARIABLE DECLARATIONS
	---------------------------------------------------------------------------------*/
  const {
    bInfo,
    selected_units,
    building_order_details,
    error_validation,
    userInfo,
    sbuildingData,
    selectedBuilding,
    buildingUnits,
  } = useContext(AppContext);
  const [user, setUser] = userInfo;
  const [single_building_data] = sbuildingData;
  const { order_id } = useParams();
  const [isLoading, setIsLoading] = useState(false);
  const [var_mdl_success, set_mdl_success] = useState(false);
  const [var_mdl_progress, set_mdl_progress] = useState(false);
  const [var_mdl_progress_page, set_mdl_progress_page] = useState(false);
  const [pestTreated, setPestTreated] = useState();
  const [form_validation_error, setFormError] = error_validation;
  const [treatmentOption, setTreatmentOptions] = useState();
  const [error, setError] = useState(false);
  const [var_add_unit, set_add_unit] = selected_units;
  const [building_info, setBuildingInfo] = bInfo;
  const [building_order, setBuildingOrder] = building_order_details;
  const [order_details, set_order_details] = useState();
  const [message, setMessage] = useState();
  const [selectFile, setSelectFile] = useState(null);
  const [, setSelectedBuilding] = selectedBuilding;
  const history = useHistory();
  const { addToast } = useToasts();

  /* ASYNC FUNCTIONS
	---------------------------------------------------------------------------------*/

  /* EVENT LISTENERS
	---------------------------------------------------------------------------------*/
  useEffect(() => {
    set_mdl_progress_page(true);
    getOrderDetails();
  }, []);

  useEffect(() => {
    if (order_details) {
      var units = order_details?.units || [];
      var temp_units = [];
      var validation_error_temp = [];
      units.map((value) => {
        if (value.isImmediate) {
          value.followup = "followup_immediately";
        } else if (value.isFollowupMonthly) {
          value.followup = "followup_month";
        } else if (value.follow_up) {
          value.followup = "followup";
        } else if (value.is_reschedule) {
          value.followup = "reschedule";
        } else {
          value.followup = "neworder";
        }
        value.pest = value.pest_name;
        value.treatment = value.treatment_name;
        value.exist = true;
        temp_units = [...temp_units, value];
        validation_error_temp = [...validation_error_temp, {}];
      });
      set_add_unit(temp_units);
      setFormError(validation_error_temp);
    }
  }, [order_details]);

  // useEffect(() => {
  //   console.log("Selected Buildings::::", selected_building);
  //   if (building_info && building_info.length) {
  //     const sBData = building_info.filter((b) => b.location_id == locationID);
  //     setSelectedBuilding(sBData?.length && sBData[0]);
  //   } else {
  //     history.goBack();
  //   }
  // }, []);

  //This effect job is for suitspot
  // useEffect(() => {
  //   if (order_details) {
  //     checkIsSuitSpot();
  //   }
  // }, [order_details]);

  function checkIsSuitSpot() {
    if (
      order_details.customer_name == "Park Property GTA" ||
      order_details.customer_name == "Park property GTA"
    ) {
      setSelectFile(true);
    } else {
      setSelectFile(false);
    }
  }

  async function getOrderDetails() {
    await fetch(orderDetailsGet + "?id=" + order_id + "&unit_number=null")
      .then((resposnse) => resposnse.json())
      .then((res) => {
        if (res.code === 200) {
          set_order_details(res.data);
          setBuildingOrder(res.data);
          setSelectedBuilding({...res.data, text: res.data.building_name, value: res.data.building_uuid})
        } else {
        }
      })
      .catch((error) => {
        console.error("Error:", error);
      });
    set_mdl_progress_page(false);
  }

  /* EVENT FUNCTIONS
	---------------------------------------------------------------------------------*/
  const onChangeInput = (event) => {
    let name = event.target.name;
    let value = event.target.value;
    if (name === "po_mo_wo") {
      value = value.replace(/^\s+/g, "");
    }
    setBuildingOrder({ ...building_order, [name]: value });
  };

  const onChangeFile = (event) => {
    const file = event.target.files[0];
  };

  const add_unit = () => {
    setFormError([...form_validation_error, {}]);
    set_add_unit([
      ...var_add_unit,
      { unit_number: "",followup: "neworder", key: var_add_unit.length, isAdded: true },
    ]);
  };
  function pushRouteDraft(isDraft = true) {
    var isValid = true;
    if(building_info[0]?.building_po_mo_wo){
      if(building_order?.building_po_mo_wo === ''){
        isValid=false;
      }
    }
    var_add_unit.map((value, i) => {
      if (
        form_validation_error[i].unit_number ||
        !value.pest_id ||
        !value.treatment_id ||
        !value.followup
      ) {
        isValid = false;
      }
    });
    if (isValid) {
      ///technician-home/order-review
      postServiceOrder(isDraft);
    } else {
      setError(true);
    }
  }
  function pushRoute(isDraft = false) {
    var isValid = true;
    if(building_info[0]?.building_po_mo_wo){
      if(building_order?.building_po_mo_wo === ''){
        isValid=false;
      }
    }
    var_add_unit.map((value, i) => {
      if (
        form_validation_error[i].unit_number ||
        !value.pest_id ||
        !value.treatment_id ||
        !value.followup
      ) {
        isValid = false;
      }
    });
    if (isValid) {
      ///technician-home/order-review
      postServiceOrder(isDraft);
    } else {
      setError(true);
    }
  }
  const doDelete = (index) => {
    var items = [...var_add_unit];
    items[index].isDeleted = true;
    items[index].isAdded = false;
    items[index].isEdited = false;
    // console.log(index)
    // items.splice(index, 1);
    // items.map((val, index) => {
    //   var temp = val;
    //   temp.key = index;
    //   items[index] = temp;
    // });
    set_add_unit(items);
  };

  async function postServiceOrder(isDraft = false) {
    var DateNTime = new Date().toISOString();
    var params = {};
    params = { ...params, orderDateAndTime: DateNTime };
    params = { ...params, orderDate: new Date().toISOString() };
    params = { ...params, building_uuid: order_details?.building_uuid };
    params = {
      ...params,
      requested_by: isDraft ? null : order_details?.building_uuid,
    };
    params = { ...params, accepted_by: null };
    params = { ...params, serviceOrderID: order_id };
    params = { ...params, po_mo_wo: building_order?.building_po_mo_wo };
    params = { ...params, LocationID: order_details?.location_id };
    var followUp = [];
    var notFollowUp = [];
    var followupMonth = [];
    // order_details.
    var isExist = false; // If user added same unit # for bedbug followup and not followup
    var_add_unit
    .filter((el) => el != null)
    .map((value) => {
      // Create a deep copy of the object to avoid mutating the original
      let newValue = { ...value };
  
      newValue.is_reschedule = newValue.followup === "reschedule" ? true : false;
      newValue.unit_number = newValue.unit_number.replace(/^[.\s]+|[.\s]+$/g, "");
  
      if (newValue.followup === "followup") {
        newValue.followup = true;
        newValue.isImmediate = false;
        followUp = [...followUp, newValue];
      } else if (newValue.followup === "followup_month") {
        newValue.followup = false;
        newValue.isFollowupMonthly = true;
        followUp = [...followUp, newValue];
      } else if (newValue.followup === "followup_immediately") {
        newValue.followup = false;
        newValue.isImmediate = true;
        notFollowUp = [...notFollowUp, newValue];
      } else {
        newValue.followup = false;
        newValue.isImmediate = false;
        newValue.isFollowupMonthly = false;
        notFollowUp = [...notFollowUp, newValue];
      }
    });

    followUp.map((value) => {
      notFollowUp.map((val) => {
        if (
          value.unit_number === val.unit_number &&
          value.pest === val.pest &&
          value.treatment === val.treatment
        ) {
          isExist = true;
        }
      });
    });
    if (!isExist) {
      notFollowUp.map((val) => {
        if (single_building_data?.second_bedbug) {
        if (
          !val.isImmediate &&
          !val.isFollowupMonthly &&
          val.pest === "Bed Bugs" &&
          val.treatment === "Spray Treatment"
        ) {
          var temp = { ...val };
          temp.followup = true;
          followUp = [...followUp, temp];
        }
      }
      });
      
    }
    followUp = alphaNumericSort(followUp, "unit_number");
    notFollowUp = alphaNumericSort(notFollowUp, "unit_number");
    followupMonth = followUp.filter((val) => val.isFollowupMonthly);
    followUp = followUp.filter((val) => !val.isFollowupMonthly);

    params = { ...params, not_followup_units: notFollowUp };
    params = { ...params, followup_units: followUp };
    params = { ...params, followup_monthly_units: followupMonth };

    const requestOptionsOrder = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(params),
    };
    setIsLoading(true);
    set_mdl_progress(true);
    await fetch(postOrder, requestOptionsOrder)
      .then((response) => response.json())
      .then((res) => {
        if (res.code && res.code === 401) {
          addToast("Session expired. Please Login again.", {
            appearance: "error",
            autoDismiss: true,
          });
          //SessionExpire();
          localStorage.removeItem("user");
          localStorage.removeItem("building");
          setUser(null);
          setBuildingInfo(null);
          setBuildingOrder({});
        } else {
          const { message } = res;
          if (res.code === 200) {
            setMessage(message);
            progress_function();
          } else {
            set_mdl_progress(false);
            addToast(
              message ? message : "Some error occured. Please try again.",
              {
                appearance: "error",
                autoDismiss: true,
              }
            );
          }
        }
      })
      .catch(() => {
        set_mdl_progress(false);
        addToast("Server side error. Please try again.", {
          appearance: "error",
          autoDismiss: true,
        });
      });
    setIsLoading(false);
  }

  const progress_function = () => {
    set_mdl_progress(false);
    set_mdl_success(true);
  };

  const page_progress_function = () => {
    set_mdl_progress_page(false);
  };

  const success_function = () => {
    setBuildingOrder({});
    set_add_unit([{ unit_number: "", followup: true }]);
    set_mdl_success(false);
    history.replace("/home");
  };

  const alphaNumericSort = (arr = [], key) => {
    const sorter = (x, y) => {
      var a = x[key];
      var b = y[key];
      const isNumber = (v) => (+v).toString() === v;
      const aPart = a.match(/\d+|\D+/g);
      const bPart = b.match(/\d+|\D+/g);
      let i = 0;
      let len = Math.min(aPart.length, bPart.length);
      while (i < len && aPart[i] === bPart[i]) {
        i++;
      }
      if (i === len) {
        return aPart.length - bPart.length;
      }
      if (isNumber(aPart[i]) && isNumber(bPart[i])) {
        return aPart[i] - bPart[i];
      }
      return aPart[i].localeCompare(bPart[i]);
    };
    arr.sort(sorter);
    return arr;
  };

  function deleteUnitApiCall(unit) {
    var deletedc_value_index = var_add_unit.findIndex(
      (val) => val.lineItemID === unit.lineItemID
    );
    const requestOptionsOrder = {
      method: "DELETE",
      headers: { "Content-Type": "application/json" },
    };
    fetch(deleteDraftOrderUnits + unit.lineItemID, requestOptionsOrder)
      .then((response) => response.json())
      .then((res) => {
        if (res.status === 200) {
          var temp_units = [...var_add_unit];
          temp_units.splice(deletedc_value_index, 1);
          set_add_unit(temp_units);
        }
        addToast(res.message, {
          appearance: res.status === 200 ? "success" : "error",
          autoDismiss: true,
        });
      })
      .catch((error) => {
        console.log("Error:::::", error);
      });
  }

  const doEdit = (index) => {
    var items = [...var_add_unit];
    items[index].isEdited = items[index].isAdded ? false : true;
    //items[index].isEdited = true;
    set_add_unit(items);
  };

  /* RENDER APP
	---------------------------------------------------------------------------------*/

  return (
    <div className="app-content">
      <div className="order-request-form-container">
        <div className="app-backto-prev-page">
          <div
            className="app-goback-button"
            onClick={() => {
              setFormError([{}]);
              setBuildingOrder({});
              set_add_unit([{ unit_number: "", followup: false, key: 0 }]);
              history.goBack();
            }}
          >
            <Icon name="arrow left" /> Go Back
          </div>
        </div>
        <Form error={error} onSubmit={() => pushRoute()} >
          <div className="app-card">
            <div className="app-card-header">
              <div className="app-card-title">Automatic Followup Request</div>
            </div>
            <div className="app-card-body">
              <div className="darft-search-toolbar">
                <Grid>
                  <Grid.Row columns={3}>
                    <Grid.Column>
                      <div className="draft-search-building">
                        <Form.Field
                          label="Location"
                          control={Input}
                          required
                          // disabled={true}
                          value={order_details?.building_name}
                          fluid
                        />
                      </div>
                    </Grid.Column>
                    <Grid.Column>
                      <Form.Group>
                        {selectFile && (
                          <Form.Field
                            id="upload_file"
                            name="upload_file"
                            label="Please upload PO/MO"
                            control={Input}
                            type="file"
                            onChange={onChangeFile}
                          />
                        )}
                      </Form.Group>
                    </Grid.Column>
                      <Grid.Column>
                        <Form.Field
                          label={
                            building_info[0]?.building_po_mo_wo?.toLowerCase() ===
                            "po"
                              ? "Purchase Order Number"
                              : building_info[0]?.building_po_mo_wo?.toLowerCase() ===
                                "mo"
                              ? "Maintenance Order Number"
                              : "Work Order Number"
                          }
                          control={Input}
                          required={building_info[0]?.building_po_mo_wo}
                          name="building_po_mo_wo"
                          id="building_po_mo_wo"
                          value={building_order?.building_po_mo_wo}
                          disabled={
                            selectFile 
                          }
                          placeholder={`${
                            order_details?.building_po_mo_wo?.toUpperCase() ||
                            building_info[0]?.building_po_mo_wo ||
                            ""
                          } number must be entered here before submitting request`}
                          onChange={onChangeInput}
                          fluid
                          maxLength={30}
                        />
                      </Grid.Column>
                  </Grid.Row>
                </Grid>
              </div>
            </div>
          </div>
          <div className="add-unit-container">
            {order_details &&
              var_add_unit.map(
                (unit, index) =>
                  !unit.isDeleted && (
                <ADD_UNIT
                  key={index}
                  index={index}
                  treatment={treatmentOption}
                  pest={pestTreated}
                  doDelete={doDelete.bind(this, index)}
                  doEdit={doEdit.bind(this, index)}
                  isTech={false}
                  deleteUnitApiCall={deleteUnitApiCall}
                />
              ))}
          </div>
          <Message
            error
            header="Required Fields"
            content="Please fill all required(*) fileds"
          />
          <div className="finish-request-actions">
            <Button
              disabled={!order_details}
              className="add-unit-button"
              color="grey"
              type="button"
              onClick={() => add_unit()}
            >
              <img src="/add-square.svg" alt="" />
              Add Unit
            </Button>
            <Button
              disabled={!order_details}
              color="orange"
              type="button" 
              onClick={() => pushRouteDraft(true)}
            >
              Save as draft
            </Button>
          
            <Button disabled={!order_details} color="red" type="submit"  >
              Submit Request
            </Button>
          </div>
        </Form>
        
      </div>
      {/***** MODAL: PROGRESS **************************************************************************/}
      <Modal
        id="mdl-progress"
        dimmer={"inverted"}
        open={var_mdl_progress}
        closeOnDimmerClick={false}
        closeOnEscape={false}
        onClose={() => {}}
      >
        <MDL_PROGRESS
          set_mdl_open={set_mdl_progress}
          var_modaltitle="Please wait"
          var_message={
            "This process can take one or two minutes. Stay on this page."
          }
          success_function={progress_function}
        ></MDL_PROGRESS>
      </Modal>

      {/***** END MODAL: PROGRESS **************************************************************************/}

      {/***** MODAL: PROGRESS **************************************************************************/}
      <Modal
        id="mdl-progress"
        dimmer={"inverted"}
        open={var_mdl_progress_page}
        closeOnDimmerClick={false}
        closeOnEscape={false}
        onClose={() => {}}
      >
        <MDL_PROGRESS
          set_mdl_open={set_mdl_progress_page}
          var_modaltitle="Please wait"
          var_message={"Fetching order details..."}
          success_function={page_progress_function}
        ></MDL_PROGRESS>
      </Modal>

      {/***** END MODAL: PROGRESS **************************************************************************/}

      {/***** MODAL: SUCCESS **************************************************************************/}
      <Modal
        id="mdl-success"
        dimmer={"inverted"}
        open={var_mdl_success}
        closeOnDimmerClick={false}
        closeOnEscape={false}
        onClose={() => {
          set_mdl_success(false);
        }}
      >
        <MDL_SUCCESS
          set_mdl_open={set_mdl_success}
          var_modaltitle="Success"
          var_message={message}
          success_function={success_function}
        ></MDL_SUCCESS>
      </Modal>

      {/***** END MODAL: SUCCESS **************************************************************************/}
    </div>
  );
}

export default OrderAutoFollowUp;
