import React, { useContext, useState, useEffect } from "react";
import { Table, Label, Button, Search, Modal } from "semantic-ui-react";
import { AppContext } from "../../../Context/AppContext";
import { useHistory } from "react-router-dom";
import {
  deleteOrder,
  deleteSchedulerOrder,
  getAllOrdersOfScheduler,
  getAllOrdersOfUser,
  getSingleBuilding,
  orderDetailsGet,
  resendConfirmationEmail,
} from "../../../constants";
import "./Home.css";
import AppPagination from "../../../components/cmp-pagination/Pagination";
import { useLocation } from "react-router-dom";
import { useToasts } from "react-toast-notifications";
import MDL_PROGRESS from "../../../components/cmp-progress/cmp-progress";
import MDL_CHANGE_STATUS from "./mdl-change-status/mdl-change-status";
import moment from "moment";

const SchedulerHome = () => {
  /* VARIABLE DECLARATIONS
	---------------------------------------------------------------------------------*/
  const {
    userInfo,
    selectedBuilding,
    selected_units,
    building_order_details,
    buildingUnits,
    error_validation,
  } = useContext(AppContext);
  const [user, setUser] = userInfo;
  const [building_units, setBuildingUnits] = buildingUnits;
  const [, setFormError] = error_validation;
  const [, set_add_unit] = selected_units;
  const location = useLocation();
  const [, setSelectedBuilding] = selectedBuilding;
  const [, setBuildingOrder] = building_order_details;
  const [orders, setOrders] = useState([]);
  const [searchResult, setSearchResult] = useState([]);
  const history = useHistory();
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(50);
  const [totalOrders, setTotalOrders] = useState(0);
  const { addToast } = useToasts();
  const [var_mdl_progress, set_mdl_progress] = useState(false);
  const [selected_order, set_selected_order] = useState();
  const [mdl_open_change_status, set_mdl_open_change_status] = useState(false);

  /* ASYNC FUNCTIONS
	---------------------------------------------------------------------------------*/

  /* EVENT LISTENERS
	---------------------------------------------------------------------------------*/
  useEffect(() => {
    getSchedulerOrders();
  }, [page, limit]);

  useEffect(() => {
    if (selected_order) onOpen_mdl_change_status(selected_order);
  }, [selected_order]);

  async function getSchedulerOrders(page_no, limit_range, q) {
    var query_params = "";
    if (q) {
      query_params =
        `?q=${q}&page=${page_no ? page_no : page}&limit=${
          limit_range ? limit_range : limit
        }&id=` + user.uuid;
    } else {
      query_params =
        `?page=${page_no ? page_no : page}&limit=${
          limit_range ? limit_range : limit
        }&id=` + user.uuid;
    }
    set_mdl_progress(true);
    await fetch(getAllOrdersOfScheduler + query_params)
      .then((response) => response.json())
      .then((res) => {
        var ordersFetch = [];
        setTotalOrders(res.body.total_order);
        if (res.statusCode === 200) {
          ordersFetch = res.body.data;
          if (
            page > 1 &&
            res.body &&
            res.body.data &&
            res.body.data.length === 0
          ) {
            setPage(page - 1);
          }
        } else {
          ordersFetch = [];
        }
        if ((page && page > 1) || (page_no && page_no > 1))
          ordersFetch = [...orders, ...ordersFetch];
        setOrders(ordersFetch);
        set_mdl_progress(false);
      })
      .catch((error) => {
        set_mdl_progress(false);
        console.error("Error:", error);
      });
  }

  /* EVENT FUNCTIONS
	---------------------------------------------------------------------------------*/

  function goViewDetail(id, unit_number) {
    history.push({ pathname: "/complete-order", state: { id, unit_number } });
  }

  function deleteSchedulerOrderApiCall(service_id) {
    const requestOptionsOrder = {
      method: "DELETE",
      headers: { "Content-Type": "application/json" },
    };
    fetch(deleteSchedulerOrder + service_id, requestOptionsOrder)
      .then((response) => response.json())
      .then((res) => {
        if (res.status === 200) {
          getSchedulerOrders(page, limit);
          setSearchResult([]);
        }
        addToast(res.message, {
          appearance: res.status === 200 ? "success" : "error",
          autoDismiss: true,
        });
      })
      .catch((error) => {
        console.log("Error:::::", error);
      });
  }

  function resendConfirmation(service_id) {
    set_mdl_progress(true);
    const requestOptionsOrder = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
    };
    fetch(resendConfirmationEmail + service_id, requestOptionsOrder)
      .then((response) => response.json())
      .then((res) => {
        if (res.status === 200) {
          getSchedulerOrders(page, limit);
          setSearchResult([]);
        }
        addToast(res.message, {
          appearance: res.status === 200 ? "success" : "warning",
          autoDismiss: true,
        });
        set_mdl_progress(false);
      })
      .catch((error) => {
        console.log("Error:::::", error);
        set_mdl_progress(false);
      });
  }

  async function goForEdit(id) {
    await fetch(orderDetailsGet + "?id=" + id + "&unit_number=" + null)
      .then((resposnse) => resposnse.json())
      .then(async (res) => {
        if (res.code === 200) {
          //setData(res.data);
          await setSelectedBuilding({
            value: res.data.building_uuid,
            text: res.data.building_name,
            building_po_mo_wo: res.data.building_po_mo_wo,
          });

          var temp_units = [];
          var validation_error_temp = [];
          var canEdit = true;
          res.data.units?.map((vals_units, index) => {
            var temp = { ...vals_units };
            temp.key = index;
            if (temp.follow_up) {
              temp.followup = "followup";
            } else if (temp.isFollowupMonthly) {
              temp.followup = "followup_month";
            } else if (temp.isImmediate) {
              temp.followup = "followup_immediately";
            } else if (temp.is_reschedule) {
              temp.followup = "reschedule";
            } else {
              temp.followup = "neworder";
            }
            if (temp.lineItemID?.length > 8) {
              canEdit = false;
            }
            validation_error_temp = [...validation_error_temp, {}];
            temp_units.push(temp);
          });
          if (canEdit) {
            await set_add_unit(temp_units);
            await setBuildingOrder({
              building_name: res.data.building_name,
              po_mo_wo: res.data.building_po_mo_wo,
            });
            await fetch(getSingleBuilding + res.data.building_uuid)
              .then((response) => response.json())
              .then((response) => {
                const final_data = response.data[0];
                if (final_data) {
                  setBuildingUnits(final_data.units ? final_data.units : []);
                }
              });
            setFormError(validation_error_temp);
            history.push({
              pathname: "/scheduler-home/update-order",
              state: {
                order_id: res.data.order_id,
                building_code: res.data.location_id,
                building_uuid: res.data.building_uuid,
                id: res.data.id,
              },
            });
          } else {
            addToast("You cannot edit this order.", {
              appearance: "error",
              autoDismiss: true,
            });
          }
        }
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  }

  async function doSearch(event) {
    const value = event.target.value.toString().toLowerCase();
    if (!value || value === "") {
      setSearchResult([]);
      getSchedulerOrders(0, limit, value);
    } else {
      // var dummySearchData=[];

      // buildingsAdmin.map(val=>{
      //     if(value.includes(val.building_name.toString().toLowerCase()) || val.building_name.toString().toLowerCase().includes(value)){
      //         dummySearchData=[...dummySearchData,val];
      //     }
      // })
      // setSearchResult(dummySearchData);
      getSchedulerOrders(0, limit, value);
    }
  }

  function handleClick() {
    history.push("/scheduler-order-draft");
  }

  function onOpen_mdl_change_status(order) {
    set_mdl_open_change_status(true);
    set_selected_order(order);
  }

  /* RENDER APP
	---------------------------------------------------------------------------------*/

  return (
    <>
      <div className="app-content">
        <div className="app-content-action app-content-action-search">
          <div className="app-content-action-right">
            <Button color="red" onClick={() => handleClick()}>
              <img src="/request-order.svg" alt="icon" /> + Request Service
              Draft
            </Button>
          </div>
        </div>
        <div className="app-card">
          <div className="app-card-header">
            <div className="app-card-title">
              Scheduler Orders List ({orders.length})
            </div>
            <div className="app-content-search">
              <Search
                placeholder="Search Building or Order #"
                onSearchChange={doSearch}
                minCharacters={20000}
              />
            </div>
          </div>

          <div className="app-table-wrapper">
            <Table unstackable>
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell>Order #</Table.HeaderCell>
                  {/* <Table.HeaderCell>Unit #</Table.HeaderCell> */}
                  <Table.HeaderCell>Building</Table.HeaderCell>
                  <Table.HeaderCell>Customer</Table.HeaderCell>
                  {/* <Table.HeaderCell>Email</Table.HeaderCell> */}
                  <Table.HeaderCell>Status</Table.HeaderCell>
                  <Table.HeaderCell>Confirmation Email</Table.HeaderCell>
                  <Table.HeaderCell>Email Actions</Table.HeaderCell>
                  <Table.HeaderCell textAlign="center">
                    Actions
                  </Table.HeaderCell>
                </Table.Row>
              </Table.Header>
              <Table.Body>
                {searchResult.length === 0 &&
                  orders &&
                  orders.map((order, i) => (
                    <Table.Row key={i}>
                      <Table.Cell>{order.order_number}</Table.Cell>
                      {/* <Table.Cell>{order.unit_number}</Table.Cell> */}
                      <Table.Cell>{order.building_name}</Table.Cell>
                      <Table.Cell>{order.customer_name} </Table.Cell>
                      {/* <Table.Cell>{order.customer_email}</Table.Cell> */}
                      <Table.Cell
                        className="td-status"
                        onClick={() => {
                          onOpen_mdl_change_status(order);
                        }}
                      >
                        {order.order_status === "completed" && (
                          <div style={{ color: "#05881F" }}>Completed</div>
                        )}
                        {order.order_status === "scheduled" && (
                          <div style={{ color: "#2196f3" }}>Scheduled</div>
                        )}
                        {order.order_status === "in progress" && (
                          <div style={{ color: "#FA8C16" }}>In Progress</div>
                        )}
                      </Table.Cell>
                      <Table.Cell>
                        {
                          <div
                            style={{
                              color: order?.confirmationWorkDateTime
                                ? "#05881F"
                                : "red",
                            }}
                          >
                            {order?.confirmationWorkDateTime
                              ? moment(order?.confirmationWorkDateTime).format(
                                  "MM-DD-YYYY hh:mm a"
                                )
                              : "Time Not Available"}
                          </div>
                        }
                      </Table.Cell>
                      <Table.Cell>
                      {order.order_status !== "completed" && (
                        <Label
                          color="orange"
                          className="resendBtn"
                          onClick={() => resendConfirmation(order.id)}
                        >
                          Resend
                        </Label>
                      )}
                      </Table.Cell>
                      <Table.Cell
                        className="td-action"
                        collapsing
                        textAlign="right"
                      >
                        {order.order_status === "in progress" && (
                          <Label
                            color="green"
                            onClick={() => goForEdit(order.id, null)}
                          >
                            Edit
                          </Label>
                        )}
                        <Label
                          color="blue"
                          onClick={() => goViewDetail(order.id, null)}
                        >
                          View
                        </Label>
                        <Label
                          color="red"
                          onClick={() => deleteSchedulerOrderApiCall(order.id)}
                        >
                          Delete
                        </Label>
                      </Table.Cell>
                    </Table.Row>
                  ))}
                {searchResult.length > 0 &&
                  searchResult.map((order, i) => (
                    <Table.Row key={i}>
                      <Table.Cell>{order.order_number}</Table.Cell>
                      {/* <Table.Cell>{order.unit_number}</Table.Cell> */}
                      <Table.Cell>{order.building_name}</Table.Cell>
                      <Table.Cell>{order.customer_name} </Table.Cell>
                      {/* <Table.Cell>{order.customer_email}</Table.Cell> */}
                      <Table.Cell
                        className="td-status"
                        onClick={() => {
                          onOpen_mdl_change_status(order);
                        }}
                      >
                        {order.order_status === "completed" && (
                          <div style={{ color: "#05881F" }}>Complete</div>
                        )}
                        {order.order_status === "scheduled" && (
                          <div style={{ color: "#05881F" }}>Scheduled</div>
                        )}
                        {order.order_status === "in progress" && (
                          <div style={{ color: "#FA8C16" }}>In Progress</div>
                        )}
                      </Table.Cell>
                      <Table.Cell>
                        {order.confirmationEmailStatus ? (
                          // ||
                          // order.order_status === "completed"
                          <div
                            style={{
                              color: order?.confirmationWorkDateTime
                                ? "#05881F"
                                : "red",
                            }}
                          >
                            {order?.confirmationWorkDateTime
                              ? moment(order?.confirmationWorkDateTime).format(
                                  "MM-DD-YYYY HH:mm a"
                                )
                              : "Time Not Available"}
                          </div>
                        ) : order.order_status !== "completed" && (
                          <Label
                            color="orange"
                            className="resendBtn"
                            onClick={() => resendConfirmation(order.id)}
                          >
                            Resend
                          </Label>
                        )}
                      </Table.Cell>
                      <Table.Cell
                        className="td-action"
                        collapsing
                        textAlign="right"
                      >
                        <Label
                          color="blue"
                          onClick={() => goViewDetail(order.id, null)}
                        >
                          View
                        </Label>
                        <Label
                          color="red"
                          onClick={() => deleteSchedulerOrderApiCall(order.id)}
                        >
                          Delete
                        </Label>
                        {order.order_status === "in progress" && (
                          <Label
                            color="green"
                            onClick={() => goForEdit(order.id, null)}
                          >
                            Edit
                          </Label>
                        )}
                      </Table.Cell>
                    </Table.Row>
                  ))}
              </Table.Body>
            </Table>
          </div>
          {orders.length === 0 && (
            <div className="nodata-placeholder">
              There is no order to display.
            </div>
          )}
        </div>
        <AppPagination
          limit={limit}
          setLimit={setLimit}
          page={page}
          setPage={setPage}
          rowsTotal={orders.length}
        />
      </div>
      <Modal
        id="mdl-change-status"
        dimmer={"inverted"}
        open={mdl_open_change_status}
        onClose={() => set_mdl_open_change_status(false)}
      >
        <MDL_CHANGE_STATUS
          set_mdl_Open={set_mdl_open_change_status}
          set_selected_order={set_selected_order}
          selected_order={selected_order}
          getSchedulerOrders={getSchedulerOrders}
        ></MDL_CHANGE_STATUS>
      </Modal>

      {/***** MODAL: PROGRESS **************************************************************************/}

      <Modal
        id="mdl-progress"
        dimmer={"inverted"}
        open={var_mdl_progress}
        closeOnDimmerClick={false}
        closeOnEscape={false}
        onClose={() => {}}
      >
        <MDL_PROGRESS
          set_mdl_open={set_mdl_progress}
          var_modaltitle="Please wait"
          var_message={"Please wait..."}
        ></MDL_PROGRESS>
      </Modal>

      {/***** END MODAL: PROGRESS **************************************************************************/}
    </>
  );
};

export default SchedulerHome;
