import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { Table, Button, Label, Modal } from 'semantic-ui-react';
import { deleteUser, getAllUsers } from '../../../constants';
import MDL_ADD_SCHEDULER from './mdl-add-scheduler/mdl-add-scheduler';
import MDL_UPDATE_SCHEDULER from './mdl-update-scheduler/mdl-update-scheduler';
import MDL_CONFIRMATION from '../../../components/cmp-confirmation/cmp-confirmation'
import { useToasts } from 'react-toast-notifications';
import AppPagination from '../../../components/cmp-pagination/Pagination';
import './SchedulerManagement.css';



function SchedulerManagement(){


    /* VARIABLE DECLARATIONS
	=================================================================================*/
    const[ users,setUsers ]=useState([]);
    const [ var_mdl_addscheduler_open, set_mdl_addscheduler_open  ] = useState(false);
    const [ var_mdl_updatescheduler_open, set_mdl_updatescheduler_open] = useState(false);
    const [ var_scheduler_data, set_scheduler_data] = useState();
    const [ var_mdl_confirmation, set_mdl_confirmation] = useState(false);
    const [ deleteIndex, setDeleteIndex ] = useState();
    const [ page, setPage ] = useState(1);
    const [ limit, setLimit ] = useState(10)
    const[,setTotalUsers]=useState(0)
    const { addToast } = useToasts();
    const history = useHistory();



    /* EVENT LISTENERS
	=================================================================================*/
	useEffect(() => {
        getListOfUsers();
	}, [limit,page])

    const updateModel=(value)=>{
        set_mdl_updatescheduler_open(value);
        getListOfUsers()
    }

    /* Async functions
	---------------------------------------------------------------------------------*/
    const getListOfUsers=async(page_no,limit_range)=>{
        await fetch(getAllUsers+`?is_role=scheduler&page=${page_no?page_no: page}&limit=${limit_range?limit_range: limit}`)
        .then(response => response.json())
        .then(res => {
            var usersFetch=[];
            const{data}=res
            if(data.length === 0){
                setPage(page-1)
            }
            data.forEach((element,pos) => {
                usersFetch=[...usersFetch,
                    {
                        uid:element.uid,
                        first_name:element.first_name,
                        last_name:element.last_name,
                        address:element.adds,
                        email:element.email,
                        contact_number:element.phone,
                        role:element.role?element.role:'',
                        key:element.uid,
                        text:element.first_name+" "+element.last_name,
                        value:element.first_name+" "+element.last_name,
                        company:element.company,
                        scheduler_building_count:element.scheduler_building_count
                    }]
            });
            setTotalUsers(res.total_user)
            if( (page && page >1) || (page_no && page_no >1) )
            usersFetch=[...users,...usersFetch];
            setUsers(usersFetch);
        })
        .catch(error=>{console.error("Error:",error)});
    }

    async function delete_scheduler(){
        var user=users[deleteIndex];
        if(user){
            const{uid}=user;

            const requestOptions = {
                method: 'DELETE',
                headers: { 'Content-Type': 'application/json' },
                body:JSON.stringify({scheduler_id:uid})
            };
            await fetch(deleteUser,requestOptions)
            .then(res=>res.json())
            .then(response=>{
                const{failed,success}=response;
                if(failed){
                    addToast(failed, {
                        appearance: 'error',
                        autoDismiss: true,
                    })
                }
                if(success){
                    getListOfUsers(0,limit)
                    addToast(success, {
                        appearance: 'success',
                        autoDismiss: true,
                    })
                }
            })
            .catch(error=>{
                console.error("Error",error);
            })
        }
    }

    /* EVENT FUNCTIONS
	=================================================================================*/
    function onOpen_mdl_add_scheduler() {
        set_mdl_addscheduler_open(true)
    }

    function onOpen_mdl_update_scheduler(scheduler) {
        set_scheduler_data(scheduler);
        set_mdl_updatescheduler_open(true);
    }

    function onOpen_mdl_confirmation(pos) {
        set_mdl_confirmation(true);
        setDeleteIndex(pos);
    }

    const goToViewScheduler = (scheduler) => {
        history.push({pathname:'/scheduler-management/view-scheduler',state:{data:scheduler}});
    }



    /* RENDER APP
	=================================================================================*/
    return(
        <>
        <div className="app-content">
            <div className="app-scheduler-container">

                <div className="app-content-action">
                    <div className="app-content-action-right">
                        <Button color="red" onClick={()=> onOpen_mdl_add_scheduler()}>
                            <img src="/plus-icon.svg" alt="icon" />Add Scheduler
                        </Button>
                    </div>
                </div>

                <div className="app-card">
                    <div className="app-card-header">
                        <div className="app-card-title">Scheduler Management ({users.length})</div>
                    </div>

                    <div className="app-table-wrapper">
                        <Table unstackable>
                            <Table.Header>
                                <Table.Row>
                                    <Table.HeaderCell>Scheduler</Table.HeaderCell>
                                    <Table.HeaderCell>Email</Table.HeaderCell>
                                    <Table.HeaderCell>Contact #</Table.HeaderCell>
                                    <Table.HeaderCell>Buildings</Table.HeaderCell>
                                    <Table.HeaderCell textAlign='center'>Actions</Table.HeaderCell>
                                </Table.Row>
                            </Table.Header>

                            <Table.Body>
                            {users.map((scheduler, i) =>
                                <Table.Row key={i}>
                                    <Table.Cell>
                                        <div className="td-customer-name">
                                            <div className="td-short-name">{scheduler.first_name && scheduler.first_name[0].toUpperCase()}{scheduler.last_name && scheduler.last_name[0].toUpperCase()}</div>
                                            <div className="td-full-name">{scheduler.first_name} {scheduler.last_name}</div>
                                        </div>
                                    </Table.Cell>
                                    <Table.Cell>{scheduler.email}</Table.Cell>
                                    <Table.Cell>{scheduler.contact_number}</Table.Cell>
                                    <Table.Cell>{scheduler.scheduler_building_count
                                    }</Table.Cell>
                                    <Table.Cell className="td-action" collapsing textAlign='right'>
										<Label color="blue" onClick={() => goToViewScheduler(scheduler)}>View</Label>
										<Label onClick={()=> onOpen_mdl_update_scheduler(scheduler)} color="green">Edit</Label>
										{/* <Label color="red"  onClick={() => onOpen_mdl_confirmation(i)}>Delete</Label> */}
                                    </Table.Cell>
                                </Table.Row>
                            )}
                            </Table.Body>
                        </Table>
                        {users.length === 0 &&
                            <div className="nodata-placeholder">
                                There is no schedulers to display
                            </div>
                        }
                    </div>
                </div>
                <AppPagination limit={limit} setLimit={setLimit} page={page} setPage={setPage} rowsTotal={users.length}/>
            </div>
        </div>


        {/***** MODAL: ADD SCHEDULER **************************************************************************/}
        <Modal id="mdl-add-scheduler"
                   dimmer={'inverted'}
                   open={var_mdl_addscheduler_open}
                   onClose={() => set_mdl_addscheduler_open(false)}>
                <MDL_ADD_SCHEDULER
                    set_mdl_open={set_mdl_addscheduler_open}
                    update_users_list={getListOfUsers}
                    limit={limit}
                    page={page}
                >
                </MDL_ADD_SCHEDULER>
            </Modal>

            {/***** END MODAL: ADD SCHEDULER **************************************************************************/}


            {/***** MODAL: UPDATE SCHEDULER **************************************************************************/}
            <Modal id="mdl-update-scheduler"
                   dimmer={'inverted'}
                   open={var_mdl_updatescheduler_open}
                   onClose={() => set_mdl_updatescheduler_open(false)}>
                <MDL_UPDATE_SCHEDULER
                    set_mdl_open = {updateModel}
                    scheduler = {var_scheduler_data}
                >
                </MDL_UPDATE_SCHEDULER>
            </Modal>

            {/***** END MODAL: UPDATE SCHEDULER **************************************************************************/}

            {/***** MODAL: DELETE CONFIRMATION **************************************************************************/}
            <Modal id="mdl-confirmation"
                   dimmer={'inverted'}
                   open={var_mdl_confirmation}
                   onClose={() => set_mdl_confirmation(false)}>
                <MDL_CONFIRMATION
                    set_mdl_open = {set_mdl_confirmation}
                    var_modaltitle = "Delete Scheduler"
                    var_message = "Are you sure want to delete this scheduler?"
                    confirmation_function = {delete_scheduler}
                >
                </MDL_CONFIRMATION>
            </Modal>

            {/***** END MODAL: DELETE CONFIRMATION **************************************************************************/}
        </>
    )

}

export default SchedulerManagement;