import React, { useState, useContext } from 'react';
import { Icon, Button, Form, Input,Modal } from 'semantic-ui-react';
import './mdl-upload-bulk-building.css';
import { AppContext } from '../../../../Context/AppContext';
import { useToasts } from 'react-toast-notifications';
import { postBulkUploadBuildings } from '../../../../constants';
import MDL_PROGRESS from "../../../../components/cmp-progress/cmp-progress";

export default function MDL_UPLOAD_BUILDING({set_mdl_open, onChangeEvent}) {

    /* VARIABLE DECLARATIONS
	---------------------------------------------------------------------------------*/
    const [ var_add_unit, set_add_unit ] = useState()
    const { userInfo } = useContext(AppContext);
    const [user, setUser] = userInfo;
    const { addToast } = useToasts();
    const [var_mdl_progress, set_mdl_progress] = useState(false);
    const [var_errors, set_errors] = useState({
        document: '',
      });




    /* EVENT LISTENERS
	---------------------------------------------------------------------------------*/
    
    
    
    
    /* EVENT FUNCTIONS
	---------------------------------------------------------------------------------*/
    const onChangeInput = (event) => {
        const file = event.target.files[0];
        if(file){
            set_add_unit(file);
        }else{
            set_add_unit(null);  
        }
    }

    const add_bulk = async () => {
      set_mdl_progress(true);
        if (!var_add_unit || !validateFile(var_add_unit)) {
          return;
        }
    
        const formData = new FormData();
        formData.append('document', var_add_unit);
        formData.append('docIndex', 1);
    
        let requestOptions = {
          method: 'POST',
          headers: {
            Authorization: 'Token ' + user.token,
          },
          body: formData,
        };
    
        requestOptions.body = formData;
    
        await fetch(postBulkUploadBuildings, requestOptions)
          .then((response) => response.json())
          .then((res) => {
            set_mdl_open(false);
            if (res.code === 200) {
              addToast(res.message, {
                appearance: 'success',
                autoDismiss: true,
              });
            } else {
              addToast(res.message, {
                appearance: 'error',
                autoDismiss: true,
              });
            }
          })
          .catch((error) => {
            console.error('Error:', error);
          });
          set_mdl_progress(false);
      };

    // const add_bulk=()=>{
    //     if(var_add_unit)
    //     onChangeEvent(var_add_unit)
    //     set_mdl_open(false);
    // }
    const validateFile = (file) => {
        const allowedFileTypes = ['application/pdf', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'];
        if (!allowedFileTypes.includes(file.type)) {
          set_errors({ ...var_errors, document: 'Only PDF and Excel files are allowed.' });
          return false;
        }
        set_errors({ ...var_errors, document: '' });
        return true;
      };

    /* RENDER APP
	---------------------------------------------------------------------------------*/
    return (
        <>
        <Form method="POST" onSubmit={add_bulk}>
            <div className="modal-header">
                <div className="modal-header-title">Bulk Buildings</div>
                <div className="modal-header-close" onClick={() => set_mdl_open(false)}>
                    <Icon name="times circle outline" />
                </div>
            </div>
            <div className="modal-content">
                
                <Form.Group>
                    <Form.Field
                        id='upload_file'
                        name='upload_file'
                        label='You can upload building(s) by choosing excel file from computer.'
                        control={Input}
                        type="file"
                        onChange={onChangeInput}
                    />
                </Form.Group>
            </div>

            <div className="modal-footer">
            {/* <a color href={process.env.PUBLIC_URL + '/BulkUnitUploadFormat.xlsx'} download="BulkUnitUploadFormat.xlsx">
            
            <Button color="grey" onClick= 
                >Download Format</Button>
            {/* </a> */}
            
            <Button type="button" color="grey" onClick= {()=>{
                fetch(process.env.PUBLIC_URL + '/BulkUpload.xlsx', {
                    method: 'GET',
                    headers: {
                      'Content-Type': 'application/xlsx',
                    },
                  })
                  .then((response) => response.blob())
                  .then((blob) => {
                    // Create blob link to download
                    const url = window.URL.createObjectURL(
                      new Blob([blob]),
                    );
                    const link = document.createElement('a');
                    link.href = url;
                    link.setAttribute(
                      'download',
                      `BulkUpload.xlsx`,
                    );
                
                    // Append to html link element page
                    document.body.appendChild(link);
                
                    // Start download
                    link.click();
                
                    // Clean up and remove the link
                    link.parentNode.removeChild(link);
                  }).catch(error=>{
                      console.error("Error:",error);
                  });
            }}
                >Download Format</Button>
                <Button type="submit" color="red">
                    Submit
                </Button>
            </div>
        </Form>
         {/***** MODAL: PROGRESS **************************************************************************/}
      <Modal
        id="mdl-progress"
        dimmer={"inverted"}
        open={var_mdl_progress}
        closeOnDimmerClick={false}
        closeOnEscape={false}
        onClose={() => {}}
      >
        <MDL_PROGRESS
          set_mdl_open={set_mdl_progress}
          var_modaltitle="Please wait"
          var_message={"Stay on this page until this process finish."}
        ></MDL_PROGRESS>
      </Modal>

      {/***** END MODAL: PROGRESS **************************************************************************/}
        </>
    )
}

