import React, { useState, useEffect, useContext } from "react";
import { Icon, Button, Form, Input } from "semantic-ui-react";
import { updateUser } from "../../../../constants";
import { useToasts } from "react-toast-notifications";
import "./mdl-update-scheduler.css";
import { AppContext } from "../../../../Context/AppContext";

export default function MDL_UPDATE_CUSTOMER({ set_mdl_open, scheduler }) {
  /* VARIABLE DECLARATIONS
	---------------------------------------------------------------------------------*/

  const { userInfo, validateTextField } = useContext(AppContext);
  const [user, setUser] = userInfo;
  const [var_update_scheduler, set_update_scheduler] = useState(scheduler);
  const { addToast } = useToasts();
  const [errors] = useState([]);

  /* EVENT LISTENERS
	---------------------------------------------------------------------------------*/
  useEffect(() => {}, []);

  /* EVENT FUNCTIONS
	---------------------------------------------------------------------------------*/
  const onChangeInput = (event) => {
    let name = event.target.name;
    let value = event.target.value;
    console.log('name',name)
    if (name === "email") {
      var pattern_email = new RegExp(
        /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i
      );
      if (!pattern_email.test(value)) {
        errors["email"] = "Please enter valid email address.";
      } else {
        errors["email"] = null;
      }
    } else if (name === "new_password") {
      if (!value || value?.length === 0) {
        errors["new_password"] = "Please write a valid new password.";
      } else if (value.length < 7) {
        errors["new_password"] = "Password length must be 8 or more.";
      } else {
        errors["new_password"] = null;
      }
    } else if (name === "first_name") {
      if (validateTextField(value) === false) {
        errors["first_name"] = "Please enter valid first name.";
      } else {
        errors["first_name"] = null;
      }
    } else if (name === "last_name") {
      if (validateTextField(value) === false) {
        errors["last_name"] = "Please enter valid last name.";
      } else {
        errors["last_name"] = null;
      }
    }
    console.log('errors',errors)
    set_update_scheduler({ ...var_update_scheduler, [name]: value });
  };

  const onSubmit_updated_data = async (e) => {
    e.preventDefault();
    if (errors["first_name"]) {
      errors["first_name"] = "Please add valid first name.";
    } else if (errors["last_name"]) {
      errors["last_name"] = "Please add valid last name.";
    } else if (errors["contact_number"]) {
      errors["contact_number"] = "Please add valid number.";
    } else if (errors["email"]) {
      errors["email"] = "Please add valid email.";
    } else {
      const { first_name, last_name, address,email, contact_number, new_password } =
        var_update_scheduler;
      const requestOptions = {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Token " + user.token,
        },
        body: JSON.stringify({
          first_name,
          last_name,
          address,
          email,
          contact_number,
          new_password,
          user_id: user.user_id,
        }),
      };
      var apiUrl = updateUser + `${var_update_scheduler.uid}`;
      await fetch(apiUrl, requestOptions)
        .then((response) => response.json())
        .then((res) => {
          if (res.code && res.code === 401) {
            addToast("Session expired. Please Login again.", {
              appearance: "error",
              autoDismiss: true,
            });
            //SessionExpire();
            localStorage.removeItem("user");
            setUser(null);
          } else {
            addToast(res.body.success, {
              appearance: "success",
              autoDismiss: true,
            });
          }
          set_mdl_open(false);
        })
        .catch((err) => {
          console.error("Error:", err);
        });
    }
  };

  /* RENDER APP
	---------------------------------------------------------------------------------*/
  return (
    <>
      <Form method="POST" onSubmit={onSubmit_updated_data}>
        <div className="modal-header">
          <div className="modal-header-title">Update Scheduler</div>
          <div
            className="modal-header-close"
            onClick={() => set_mdl_open(false)}
          >
            <Icon name="times circle outline" />
          </div>
        </div>
        <div className="modal-content">
          <Form.Group>
            <Form.Field
              id="first_name"
              name="first_name"
              label="First Name"
              control={Input}
              required
              onChange={onChangeInput}
              value={var_update_scheduler.first_name}
              error={errors["first_name"]}
            />
          </Form.Group>
          <Form.Group>
            <Form.Field
              id="last_name"
              name="last_name"
              label="Last Name"
              control={Input}
              required
              onChange={onChangeInput}
              value={var_update_scheduler.last_name}
              error={errors["last_name"]}
            />
          </Form.Group>
          <Form.Group>
            <Form.Field
              id="email"
              name="email"
              label="Email"
              control={Input}
              required
              onChange={onChangeInput}
              error={errors["email"]}
              value={var_update_scheduler.email}
            />
          </Form.Group>
          <Form.Group>
            <Form.Field
              id="password"
              name="new_password"
              required={false}
              label="New Password"
              type="password"
              error={errors["password"]}
              control={Input}
              onChange={onChangeInput}
            />
          </Form.Group>
          {/* <Form.Group>
              <Form.Field
                id="address"
                name="address"
                label="Address"
                control={Input}
                required
                onChange={onChangeInput}
                value={var_update_scheduler.address}
              />
            </Form.Group> */}
        </div>

        <div className="modal-footer">
          <Button type="submit" color="red">
            Update Scheduler
          </Button>
        </div>
      </Form>
    </>
  );
}
