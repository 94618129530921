import React, { useContext, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import Logo from "../../logo.png";
import MDL_CONFIRMATION from "../../components/cmp-confirmation/cmp-confirmation";
import MDL_UPDATE_PASSWORD from "../../components/mdl-update-password/mdl-scheduler-update-password";

import "./Header.css";
import { Modal } from "semantic-ui-react";
import { AppContext } from "../../Context/AppContext";
import { buildingStat } from "../../constants";

function Header({ user, setUser, building_info, setBuildingInfo, navToggle }) {
  /* VARIABLE DECLARATIONS
	---------------------------------------------------------------------------------*/
  const { orderCunt, selectedBuilding } = useContext(AppContext);
  const [ordersCount, setOrderCount] = orderCunt;
  const [selected_building, setSelectedBuilding] = selectedBuilding;
  const [var_mdl_confirmation, set_mdl_confirmation] = useState(false);
  const [mdl_update_password_open, set_mdl_update_password_open] =
    useState(false);
  const history = useHistory();

  /* EVENT FUNCTIONS
	---------------------------------------------------------------------------------*/
  function onOpen_mdl_update_password() {
    set_mdl_update_password_open(true);
  }
  useState(() => {
    const { role } = user;
    if (role && role === "customer") getBuildingStat();
  }, [building_info]);

  /**
   * Get the stats of building
   */
  async function getBuildingStat() {
    if (building_info && building_info.uuid) {
      await fetch(buildingStat + `${building_info.uuid}`)
        .then((response) => response.json())
        .then((res) => {
          if (res.code === 200) setOrderCount(res);
        })
        .catch((error) => {
          console.error("Error:", error);
        });
    } else {
      setBuildingInfo(null);
    }
  }
  const getInitials = function (string) {
    if (string) {
      var names = string.split(" "),
        initials = names[0].substring(0, 1).toUpperCase();

      if (names.length > 1) {
        initials += names[names.length - 1].substring(0, 1).toUpperCase();
      }
      return initials;
    } else {
      return "";
    }
  };

  /* RENDER APP
	---------------------------------------------------------------------------------*/
  return user ? (
    user.role === "customer" && building_info?.length === 0 ? null : (
      <header className="app-header">
        <div className="app-container">
          <div className="app-header-inner">
            <div className="app-header-logo">
              <Link
                to={
                  user.role === "customer"
                    ? "/home"
                    : user.role === "technician"
                    ? "/technician-home"
                    : user.role === "admin"
                    ? "/dashboard"
                    : "/scheduler-home"
                }
              >
                <img src={Logo} alt="PestPac" />
              </Link>
              {user.role === "admin" && (
                <div className="mobile-nav-toggler" onClick={() => navToggle()}>
                  <img src="./hamburger.svg" alt="" />
                </div>
              )}
            </div>

            <div className="app-header-content">
              <div className="app-header-user">
                <div className="loggedin-user">
                  {user.role === "scheduler" && (
                    <div
                      className="update-pass"
                      name="Update Password"
                      onClick={() => onOpen_mdl_update_password()}
                    >
                      <img
                        src="./icon-padlock.svg"
                        alt="Password"
                        width={15}
                        height={15}
                      />
                      <span>Update Password</span>
                    </div>
                  )}
                  {user.role === "technician" && (
                    <div
                      className="update-pass"
                      name="Update Password"
                      onClick={() => onOpen_mdl_update_password()}
                    >
                      <img
                        src="./icon-padlock.svg"
                        alt="Password"
                        width={15}
                        height={15}
                      />
                      <span>Update Password</span>
                    </div>
                  )}
                  {user.role === "customer" && (
                    <div
                      className="requests-contain"
                      name="Documents"
                      onClick={() => history.push("/documents")}
                    >
                      <img
                        title="Documents"
                        src={`${window.location.origin}/folder-icon.png`}
                        alt="documents"
                      />
                    </div>
                  )}
                  {/* {user.role==="customer" &&
                                    <div className="requests-contain" name="Update Password" onClick={() => history.push("/followup-requests")} >
                                        <img title="Follow Up Requests" src={`${window.location.origin}/notification.svg`} alt="notification" />
                                        <span className="requests-count">{ordersCount?ordersCount.building_request_count:0}</span>
                                    </div>
                                } */}
                  <div className="loggedin-user-short">
                    {user.role === "customer" ? (
                      <div
                        title={selected_building && selected_building?.label}
                      >
                        {getInitials(user?.firstName)}
                      </div>
                    ) : (
                      <div title={user.firstName + " " + user.lastName}>
                        {user.firstName && `${user.firstName[0]}`}{" "}
                        {user.lastName && `${user.lastName[0]}`}
                      </div>
                    )}
                  </div>
                  {user.role !== "admin" && (
                    <div
                      className="logout-content"
                      name="Logout"
                      onClick={() => set_mdl_confirmation(true)}
                    >
                      <span className="logout-img">
                        <img src="./logout.svg" alt="Logout" />
                      </span>
                      <span>Logout</span>
                    </div>
                  )}
                  {/* <div className="loggedin-username">
                                    {user.role==="customer"?"" : user.firstName+ " " +user.lastName }
                                </div> */}
                </div>
              </div>
            </div>
          </div>
          {/***** MODAL: DELETE CONFIRMATION **************************************************************************/}
          <Modal
            id="mdl-confirmation"
            dimmer={"inverted"}
            open={var_mdl_confirmation}
            onClose={() => set_mdl_confirmation(false)}
          >
            <MDL_CONFIRMATION
              set_mdl_open={set_mdl_confirmation}
              var_modaltitle="Logout"
              var_message="Are you sure you want to log out?"
              confirmation_function={() => {
                localStorage.removeItem("user");
                localStorage.removeItem("building");
                setSelectedBuilding(null);
                if (user.role === "customer") {
                  setBuildingInfo(null);
                  history.replace("/");
                } else if (user.role === "technician") {
                  history.replace("/technician");
                } else {
                  history.replace("/scheduler");
                }
                setUser(null);
              }}
            ></MDL_CONFIRMATION>
          </Modal>

          {/***** MODAL: UPDATE PASSWORD - SCHEDULER **************************************************************************/}
          <Modal
            id="mdl-forgot-pin-customer"
            dimmer={"inverted"}
            open={mdl_update_password_open}
            onClose={() => set_mdl_update_password_open(false)}
          >
            <MDL_UPDATE_PASSWORD
              set_mdl_open={set_mdl_update_password_open}
            ></MDL_UPDATE_PASSWORD>
          </Modal>

          {/***** END MODAL: UPDATE PASSWORD - SCHEDULER **************************************************************************/}
        </div>
      </header>
    )
  ) : null;
}

export default Header;
