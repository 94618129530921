import React, { useContext, useEffect, useState } from "react";
import { Form, Icon, Button, Grid, Loader } from "semantic-ui-react";
import "../../../building-history/BuildingHistory.css";
import { AppContext } from "../../../../../Context/AppContext";
import { getUnitSummaryAPI } from "../../../../../constants";
import moment from "moment";

const MDL_UNIT_HISTORY = ({ set_mdl_open, unit_number, set_selected_unit }) => {
  const { selectedBuilding } = useContext(AppContext);
  const [selected_building] = selectedBuilding;
  const [building_summary, setBuilding_summary] = useState([]);
  const [var_loading, set_loading] = useState(false);

  useEffect(() => {
    if (unit_number) getUnitSummary();
  }, [unit_number]);

  async function getUnitSummary() {
    set_loading(true);
    let API_URL =
      getUnitSummaryAPI + selected_building?.value + `?unit=` + unit_number;

    await fetch(API_URL)
      .then((response) => response.json())
      .then((response) => {
        if (response.data) setBuilding_summary(response?.data?.completedOrders);
        else {
          setBuilding_summary([]);
        }
        set_loading(false);
      });
  }

  function closeModal() {
    set_selected_unit(null);
    set_mdl_open(false);
  }

  return (
    <Form>
      <div className="modal-header">
        <div className="modal-header-title">Unit# {unit_number} History</div>
        <div
          className="modal-header-close"
          onKeyPress={() => set_mdl_open(false)}
          onClick={() => closeModal()}
        >
          <Icon name="times circle outline" />
        </div>
      </div>
      <div className="modal-content" style={{ overflow: "scroll" }}>
        <Grid
          style={{
            margin: "0",
            overflowY: "auto",
            whiteSpace: "nowrap",
            minWidth: "1300px",
          }}
        >
          <Grid.Column computer={2}>
            <div className="order-history-item">
              <span>Order #</span>
            </div>
          </Grid.Column>
          <Grid.Column computer={3}>
            <div className="order-history-item">
              <span>Treatment</span>
            </div>
          </Grid.Column>
          <Grid.Column computer={2}>
            <div className="order-history-item">
              <span>Work Date</span>
            </div>
          </Grid.Column>
          <Grid.Column computer={2}>
            <div className="order-history-item">
              <span>Time Window</span>
            </div>
          </Grid.Column>

          <Grid.Column computer={2}>
            <div className="order-history-item">
              <span>Activity Level</span>
            </div>
          </Grid.Column>
          <Grid.Column computer={2}>
            <div className="order-history-item">
              <span>Sanitation Level</span>
            </div>
          </Grid.Column>
          <Grid.Column computer={2}>
            <div className="order-history-item">
              <span>Prep. Level</span>
            </div>
          </Grid.Column>
          <Grid.Column computer={1}>
            <div className="order-history-item">
              <span>Structural</span>
            </div>
          </Grid.Column>
          {!var_loading &&
            building_summary?.map((summary) => (
              <>
                <Grid.Column computer={2}>
                  <div className="order-history-item">
                    <span></span>
                    <span>{summary.order_number}</span>
                  </div>
                </Grid.Column>
                <Grid.Column computer={3}>
                  <div className="order-history-item">
                    <span></span>
                    <span>
                      {summary.pest_name} {summary.treatment_name}
                    </span>
                  </div>
                </Grid.Column>
                <Grid.Column computer={2}>
                  <div className="order-history-item">
                    <span></span>
                    <span>
                      {moment(summary.work_date).format("MM-DD-YYYY")}
                    </span>
                  </div>
                </Grid.Column>
                <Grid.Column computer={2}>
                  <div className="order-history-item">
                    <span></span>
                    <span>{summary.time_range || "N/A"}</span>
                  </div>
                </Grid.Column>

                <Grid.Column computer={2}>
                  <div className="order-history-item">
                    <span></span>
                    <span>{summary.activity_level || "N/A"}</span>
                  </div>
                </Grid.Column>
                <Grid.Column computer={2}>
                  <div className="order-history-item">
                    <span></span>
                    <span>{summary.sanitation_level || "N/A"}</span>
                  </div>
                </Grid.Column>
                <Grid.Column computer={2}>
                  <div className="order-history-item">
                    <span></span>
                    <span>{summary.prep_level || "N/A"}</span>
                  </div>
                </Grid.Column>
                <Grid.Column computer={1}>
                  <div className="order-history-item">
                    <span></span>
                    <span>
                      {(summary.structural_issues && "Structural Issues") ||
                        "N/A"}
                    </span>
                  </div>
                </Grid.Column>

                <Grid.Column
                  computer={16}
                  tablet={16}
                  mobile={16}
                  style={{ whiteSpace: "wrap" }}
                  className="comment-spacing border-bottom comment-text"
                >
                  <span>{summary.comment || "No Comment"}</span>
                </Grid.Column>
              </>
            ))}
          <Grid.Column width={16}>
            {var_loading && <Loader active />}
          </Grid.Column>
          {!var_loading && building_summary.length === 0 && (
            <Grid.Column width={16}>
              <div className="order-history-item">
                <span></span>
                <div
                  style={{
                    width: "100%",
                    height: "100%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <span>No Data</span>
                </div>
              </div>
            </Grid.Column>
          )}
        </Grid>
      </div>
      <div className="modal-footer">
        <Button color="red" onClick={() => closeModal()}>
          Close
        </Button>
        {/* <Button color="green">Submit</Button> */}
      </div>
    </Form>
  );
};

export default MDL_UNIT_HISTORY;
