import React, { useState, useEffect } from "react";
import { Form, Button, Label } from "semantic-ui-react";

import "./Pagination.css";

const AppPagination = ({
  limit,
  setLimit,
  page,
  setPage,
  rowsTotal,
  totalCount = 10,
  showAll = false,
}) => {
  /* VARIABLE DECLARATIONS
	---------------------------------------------------------------------------------*/
  var tableRows = rowsTotal;
  const [var_displayrows, set_displayrows] = useState(50);

  /* ASYNC FUNCTIONS
	---------------------------------------------------------------------------------*/

  /* EVENT LISTENERS
	---------------------------------------------------------------------------------*/
  useEffect(() => {}, []);

  // useEffect(() => {
  //     if(tableRows>0){
  //         set_totalpages(Math.ceil(tableRows/var_displayrows));
  //     }
  //     // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [tableRows]);

  /* EVENT FUNCTIONS
	---------------------------------------------------------------------------------*/
  function onChangePageShow(event, { value }) {
    // set_totalpages(Math.ceil(tableRows/value));
    set_displayrows(value);
    setPage(1);
    setLimit(value);
  }

  /* RENDER APP
	---------------------------------------------------------------------------------*/
  return (
    <>
      {tableRows > 0 && (
        <div className="app-pagination-wrapper">
          <div className="pagination-group">
            <Form className="pagination-form">
              <Form.Group>
                <Form.Select
                  className="pagination-options"
                  compact
                  id="pagination_options"
                  name="pagination_options"
                  placeholder="50"
                  onChange={onChangePageShow}
                  value={var_displayrows}
                  options={
                    showAll
                      ? [
                          { text: 50, value: 50 },
                          { text: 75, value: 75 },
                          { text: 100, value: 100 },
                          { text: 125, value: 125 },
                          { text: 150, value: 150 },
                          { text: "All", value: "all" },
                        ]
                      : [
                          { text: 50, value: 50 },
                          { text: 75, value: 75 },
                          { text: 100, value: 100 },
                          { text: 125, value: 125 },
                          { text: 150, value: 150 },
                        ]
                  }
                />
              </Form.Group>
            </Form>
            {
              // tableRows < totalCount &&
              <div className="button-load-more">
                <Button color="grey" onClick={() => setPage(page + 1)}>
                  Load more
                </Button>
              </div>
            }
          </div>
        </div>
      )}
    </>
  );
};

export default AppPagination;
